import React, { useEffect, useState } from "react";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import { useNavigate } from "react-router-dom";
import LeftNarrowIcon from "../../assets/leftNarrowIcon.svg";
import FacebookIcon from "../../assets/facebookLineIcon.svg";
import InstagramIcon from "../../assets/instagramLineIcon.svg";
import WhatsappIcon from "../../assets/whatsappIcon.svg";
import ShareIcon from "../../assets/shareIcon.svg";
import Button from "../../common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  creditBalanceData,
  isUpdateSidebarValueData,
  updateSidebarValue,
  getCreditBalance,
} from "../../redux/dashbordSlice/dashbordSlice";
import axios from "axios";
import moment from "moment";
import FileSaver, { saveAs } from "file-saver";
import { logout } from "../../Utils/Logout";

const GenerateSingleImageSideBar = () => {
  const navigate = useNavigate();

  const creditBalance = useSelector(creditBalanceData);
  const accessToken = localStorage.getItem("AccessToken");
  const imageIdData = localStorage.getItem("imageIdOrignandRegen");

  const [originalImgData, setOriginalImgData] = useState();
  const [regenImgData, setRegenImgData] = useState();

  const isUpdateSidebarValue = useSelector(isUpdateSidebarValueData);

  const dispatch = useDispatch();

  const regenData = { image_id: JSON.parse(imageIdData)?.regenerated_image_id };


  const getOneOriginalImage = async () => {
    // setIsLoderOriginalImg(true);
    const data = { image_id: JSON.parse(imageIdData)?.original_image_id };

    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-one-original-image/",
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        //console.log("side bar data--- ", response.data, response);
        setOriginalImgData(response?.data?.Original_Image_data);
        // setOriginalImg(response?.data?.Original_Image_data?.original_image);
        // setIsLoderOriginalImg(false);
      }
    } catch (error) {
      //console.log("Axios Error: ", error);
      // setOriginalImgErrorMsg(error?.response?.data?.Message);
      // setIsLoderOriginalImg(false);
      if (error?.response?.status === 401) {
        logout()
      }
    }
  };

  const getOneRegenImage = async () => {
    // setIsLoderOriginalImg(true);

    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-one-regen-image/",
        regenData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        //console.log("side bar data--- ", response.data, response);
        setRegenImgData(response?.data?.Regenerated_Image_data);
        // setOriginalImg(response?.data?.Original_Image_data?.original_image);
        // setIsLoderOriginalImg(false);
      }
    } catch (error) {
      //console.log("Axios Error: ", error);
      // setOriginalImgErrorMsg(error?.response?.data?.Message);
      // setIsLoderOriginalImg(false);
      if (error?.response?.status === 401) {
        logout()
      }
    }
  };



  const getCreditBalanceApi = async () => {
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-credit-balance/",
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        //console.log(response.data, response);
        dispatch(getCreditBalance(response?.data?.Credits));
      }
    } catch (error) {
      //console.log("Axios Error: ", error);
      dispatch(getCreditBalance(0));
      if (error?.response?.status === 401) {
        logout()
      }
    }
  };

  useEffect(() => {
    getCreditBalanceApi();
    getOneRegenImage();
  }, []);

  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  const handleImageLoad = (event) => {
    const img = event.target;
    setImgWidth(img.naturalWidth);
    setImgHeight(img.naturalHeight);
  };

  useEffect(() => {
    getOneOriginalImage();
    if (isUpdateSidebarValue) {
      window.location.reload();
    }
  }, []);

  const handleSingleImageUpdate = () => {
    navigate("/updateuploadedimage");
  };

  const imageUrl = regenImgData?.regenerated_image;

  // Define the function to share the image on WhatsApp
  // const shareOnWhatsApp = () => {
  //   // Create the WhatsApp URL for sharing the image
  //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
  //     "Check out this image: " + imageUrl
  //   )}`;

  //   // Open the WhatsApp URL in a new window or tab
  //   window.open(whatsappUrl, "_blank");
  // };

  // const shareToInstagram = () => {
  //   // Create the Instagram intent URL
  //   const instagramUrl = `https://www.instagram.com/create/story?url=${encodeURIComponent(
  //     imageUrl
  //   )}`;

  //   // Redirect the user to the Instagram URL
  //   // window.location.href = instagramUrl;
  //   window.open(instagramUrl, "_blank");
  // };

  // const shareOnFacebook = () => {
  //   // Create the Facebook share dialog URL
  //   const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //     contentUrl
  //   )}&picture=${encodeURIComponent(imageUrl)}`;

  //   // Open the Facebook share dialog in a new window
  //   window.open(facebookShareUrl, "_blank");
  // };

  const handleDownload = () => {
    FileSaver.saveAs(imageUrl, imageIdData?.regenerated_image || "livingimage-image.png");
  };

  return (
    <>
      <div
        className="hidden lg:flex top-0 left-0 w-80 h-screen transition-transform z-50"
      // className={`${
      //   isOpenSideBar ? "" : "-translate-x-full "
      // } hidden lg:flex top-0 left-0 w-80 h-screen transition-transform z-50`}
      >
        <div className="h-full flex flex-col justify-between px-5 py-4 overflow-y-auto bg-[#fff]">
          <ul className="space-y-4 font-medium">
            <div className="flex items-center w-full p-5">
              <div className="flex gap-3 items-center w-full justify-center cursor-pointer" onClick={() => navigate("/")}>
                <img
                  className="h-[21px] w-[21px]"
                  loading="eager"
                  alt=""
                  src={HeaderIcon}
                //   onClick={() => handleRout("/")}
                />
                <span className="uppercase font-bold font-nunito-sans text-[24px] leading-[21px]">
                  Living Image
                </span>
              </div>
            </div>
            <div>
                <div className="flex gap-1 justify-center cursor-pointer" onClick={() => navigate("/dashboard") }>
                  <img src={LeftNarrowIcon} alt="img" />
                  <span className="font-nunito-sans font-bold text-xl text-[#111827] underline">
                    Go Back
                  </span>
                </div>
              </div>
            <li>
              
              <div className="flex flex-col justify-center items-center gap-y-8 my-10">
                <div className="font-nunito-sans font-normal text-lg  px-[30px] py-[8px] rounded-full flex flex-col justify-center items-center text-[#000] border-[2px] border-[#FF5A82]">
                  {originalImgData?.public
                    ? "Public"
                    : "Private"}
                </div>
                <div className="font-nunito-sans font-bold text-xl text-[#000] flex flex-col justify-center items-center ">
                  Regenerated At
                  <span className="font-normal text-[#616161]">
                    {originalImgData?.regenerated_at
                      ? originalImgData?.regenerated_at
                      : ""}
                  </span>
                </div>
                <div className="font-nunito-sans font-bold text-xl text-[#000] flex flex-col justify-center items-center ">
                  Next Regeneration At
                  <span className="font-normal text-[#616161]">
                    {originalImgData?.next_regeneration_at
                      ? originalImgData?.next_regeneration_at
                      : ""}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="font-nunito-sans font-semibold text-base text-[#000] text-center px-5 mb-10">
                To regenerate it will cost 1 Credit and you have {" "}
                <span className="text-[#FF5A82]">{creditBalance}</span>{" "}
                <span className="">Credits Available</span>
              </div>
            </li>
            <li>
              <div className="flex flex-col justify-center items-center gap-y-5 mb-10">
                <Button
                  name={"Edit Frequency"}
                  className={
                    "font-nunito-sans font-extrabold text-2xl text-[#000]"
                  }
                  onClick={handleSingleImageUpdate}
                />
                {/* <div className="font-nunito-sans font-semibold text-base text-[#000] text-center px-3">
                  To regenerate the image it will cost 1 Credit per regeneration.
                </div> */}
              </div>
            </li>
            <li>
              <div className="flex flex-col justify-center items-center gap-y-5 mb-10">
                <button
                  className="font-nunito-sans font-extrabold text-2xl text-[#000] px-[40px] py-[10px] border-[3px] border-[#000] rounded-full"
                  onClick={handleDownload}
                >
                  Download HD
                </button>
                <div className="font-nunito-sans font-semibold text-lg text-[#616161] text-center px-3">
                  {imgHeight !== 0 ? imgHeight + " x " +  imgWidth + "px" : ""}
                </div>
              </div>
              <img
                src={imageUrl}
                alt="img"
                className="hidden"
                onLoad={handleImageLoad}
              />
            </li>
          </ul>
          <ul className="space-y-1 font-medium">
            {/* <li className=" mb-20">
              <div className="flex justify-center items-center flex-col gap-3">
                <div className="flex gap-4 justify-center items-center">
                  <img src={ShareIcon} alt="img" />
                  <span className="font-nunito-sans font-bold text-lg text-[#616161]">
                    Share via
                  </span>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <img
                    src={WhatsappIcon}
                    alt="img"
                    className="cursor-pointer"
                    onClick={shareOnWhatsApp}
                  />
                  <img
                    src={InstagramIcon}
                    alt="img"
                    className="cursor-pointer"
                    onClick={shareToInstagram}
                  />
                  <img
                    src={FacebookIcon}
                    alt="img"
                    className="cursor-pointer"
                    onClick={shareOnFacebook}
                  />
                </div>
              </div>
            </li> */}
            <li
              onClick={() => {
                navigate(-1);
                dispatch(updateSidebarValue(false));
              }}
            >
              
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default GenerateSingleImageSideBar;
