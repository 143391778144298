import React from "react";
import Image01 from "../../../assets/home/homeHeaderImg.png";
import PlayIcon from "../../../assets/home/playIcon.svg";
import Image02 from "../../../assets/home/image01.png";
import Image03 from "../../../assets/home/image02.png";
import Image04 from "../../../assets/home/image03.png";
import Image05 from "../../../assets/home/image04.png";
import Button from "../../../common/button";
import "../../../styles/index.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const HomeHead = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("AccessToken");

  const [prompt, setPrompt] = useState("");

  return (
    <>
      <div
        class="relative flex flex-col items-center justify-center w-full min-w-full lg:py-32 py-10 bg-white transition-bg"
        style={{
          minHeight: "calc(100vh - 91px)",
        }}
      >
        <div class="absolute inset-0 overflow-hidden">
          <div class="jumbo absolute -inset-[10px] opacity-50"></div>
        </div>
        <div className="container mx-auto z-10 flex justify-between items-center">
          <div className="flex flex-col gap-7 items-center justify-start container mx-auto sm:w-[50%] w-[100%]">
            <h1 className="font-bold font-nunito-sans lg:text-7xl text-4xl sm:text-4xl md:text-4xl text-black text-center">
              Bring Artwork to life.
            </h1>
            <div className="font-normal font-nunito-sans px-4 lg:text-2xl text-lg sm:text-lg md:text-lg text-black text-center">
              Use our dynamic image processing to change your image over time.
            </div>
            <div className="flex flex-col gap-4 justify-center items-center">
              {/* <Button
                onClick={() => navigate("/dashboard?upload=true")}
                name={"Upload Image"}
                className="font-normal font-nunito-sans text-2xl text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
              /> */}
              <div className="rounded-[120px] p-0 flex border-2 border-black">
                <input
                  type="text"
                  placeholder="Enter prompt. eg. Green plant growing through head of a man."
                  className="p-3 w-[180px] sm:w-[180px] md:w-[400px] lg:w-[500px] bg-transparent home-input text-xl"
                  onChange={(e) => setPrompt(e.target.value)}
                />

                <Button
                  onClick={() =>
                    navigate(
                      accessToken
                        ? `/dashboard?upload=true&prompt=true&promptText=${prompt}`
                        : `/register?upload=true&prompt=true&promptText=${prompt}`
                    )
                  }
                  name={"Generate"}
                  className="font-normal font-nunito-sans text-2xl text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
                />
              </div>
              <div className="p-2 text-center font-nunito-sans font-semibold lg:text-xl text-base sm:text-base md:text-base text-[#000] mt-5">
                Change seasons in an image base on your location.
              </div>
            </div>
            <div className="flex gap-4 justify-center lg:items-center items-start sm:items-start md:items-start flex-col sm:flex-col md:flex-col lg:flex-row sm:ml-0 ml-10">
              <div className="font-bold font-nunito-sans text-lg text-[#000] ">
                A cabin
                <div className="font-bold font-nunito-sans text-lg text-[#616161]">
                  Weather based in California
                </div>
              </div>
              <div className="flex gap-1 lg:gap-4 sm:gap-4 md:gap-1 justify-start sm:justify-center items-center flex-wrap">
                <img
                  onClick={() => navigate("/dashboard?upload=true&image=2")}
                  src={Image02}
                  alt="img"
                  className="h-[100px] w-[93px] rounded-[10px] object-contain cursor-pointer"
                />
                <img
                  onClick={() => navigate("/dashboard?upload=true&image=3")}
                  src={Image03}
                  alt="img"
                  className="h-[100px] w-[93px] rounded-[10px] object-contain cursor-pointer"
                />
                <img
                  onClick={() => navigate("/dashboard?upload=true&image=4")}
                  src={Image04}
                  alt="img"
                  className="h-[100px] w-[93px] rounded-[10px] object-contain cursor-pointer"
                />
                <img
                  onClick={() => navigate("/dashboard?upload=true&image=5")}
                  src={Image05}
                  alt="img"
                  className="h-[100px] w-[93px] rounded-[10px] object-contain cursor-pointer"
                />
              </div>
            </div>
          </div>
          {/* <div className="hidden lg:flex sm:hidden md:hidden mx-auto">
            <div className="relative flex justify-center items-center h-[530px] w-[535px]">
              <img src={PlayIcon} alt="PlayIcon" className="absolute" />
              <img
                src={Image01}
                alt="Img"
                className="h-full w-full object-contain"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HomeHead;
