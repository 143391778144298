import React, { useState, useEffect } from "react";
import RightIcon from "../../assets/rightIcon.svg";
import Button from "../../common/button";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PlanCards = (props) => {
  const [planType, setPlanType] = useState(["Monthly", "Annual"]);
  const [selectedPlanType, setSelectedPlanType] = useState(0);
  const [plans, setPlans] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const navigate = useNavigate();
  const membershipId = localStorage.getItem("MembershipId");
  const accessToken = localStorage.getItem("AccessToken");

  const getPlans = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.livingimage.io/api/get_membership/",
        null
      );
      if (response?.status === 200) {
        setPlans(response.data?.Membership_details);
        console.log(response.data?.Membership_details);
      }
      setLoading(false);
    } catch (error) {
      console.log("Axios Error: ", error);
      setErrorMessage(error.message || "An error occurred");
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleButtonClick = (plan) => {
    if (
      plan.btnTxt === "Upgrade" ||
      plan.btnTxt === "Downgrade"
    ) {
      setSelectedPlan(plan);

      if (props?.fromPricing === true){
        setShowPopup(false);
        navigateToPlan(plan)
      } else {
        setShowPopup(true);
      }
      
      
    } else {
      navigateToPlan(plan);
    }
  };

  const navigateToPlan = (plan) => {
    navigate(
      accessToken && membershipId && membershipId !== "null" && plan?.fromPricing !== true
        ? `/change-plan?id=${plan?.membership_id}`
        : accessToken && membershipId && membershipId !== "null" && plan?.fromPricing === true
        ? `/upgrade`
        : accessToken
        ? `/checkout?type=membership&id=${plan?.membership_id}`
        : `/login`
    );
  };
  

  const handleConfirm = () => {
    setShowPopup(false);
    navigateToPlan(selectedPlan);
  };

  const PlanCard = (props) => {
    return (
      <div className="mb-6 lg:mb-0 md:flex w-full px-6 sm:px-0">
        <div
          className="block h-full   border-2 p-5 border-transparent bg-white relative w-full max-w-[378px]"
          style={{
            borderImage:
              "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
            borderImageSlice: "1",
          }}
        >
          <div
            className={`w-full flex justify-center items-center absolute top-[-3%] left-0 ${
              props?.featured === "true" || props?.featured === true
                ? ""
                : "hidden"
            }`}
          >
            <div className="font-nunito-sans font-extrabold text-lg text-[#fff] rounded-lg py-1 px-5 bg-[#FF5A82] w-fit">
              Most Popular
            </div>
          </div>
          <div
            className="border-b-2 border-transparent text-center dark:border-opacity-10"
            style={{
              borderImage:
                "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
              borderImageSlice: "1",
            }}
          >
            <div className="mb-4 text-sm text-start">
              <div className="font-extrabold font-nunito-sans lg:text-2xl text-xl sm:text-xl md:text-xl  text-[#000]">
                {props?.membershipName}
              </div>
            </div>
            <div className="mb-6 flex flex-col gap-4">
              <div className="font-extrabold font-nunito-sans lg:text-5xl text-[38px] sm:text-[38px] md:text-[38px] leading-[51px] text-[#000] text-start">
                ${props?.price}
                <span className="font-extrabold font-nunito-sans lg:text-2xl text-xl sm:text-xl md:text-xl text-[#000]">
                  /{props?.duration === 30 ? "month" : "year"}
                </span>
              </div>
              <div className="font-normal font-nunito-sans text-lg text-[#000] text-start">
                Get {props?.credits} credits every{" "}
                {props?.duration === 30 ? "month" : "year"}.
              </div>
            </div>
          </div>
          <div className="py-6">
            <div className="list-inside">
              <li className="flex justify-center items-center text-base font-normal font-nunito-sans text-[#616161] gap-2">
                <div className="bg-[#D8FAF0] rounded-full p-1 h-[18px] w-[18px] flex justify-center items-center">
                  <img src={RightIcon} alt="icon" />
                </div>
                {props?.f1}
              </li>
              <li className="flex justify-center items-center text-base font-normal font-nunito-sans text-[#616161] gap-2">
                <div className="bg-[#D8FAF0] rounded-full p-1 h-[18px] w-[18px] flex justify-center items-center">
                  <img src={RightIcon} alt="icon" />
                </div>
                {props?.f2}
              </li>
              <li className="flex justify-center items-center text-base font-normal font-nunito-sans text-[#616161] gap-2">
                <div className="bg-[#D8FAF0] rounded-full p-1 h-[18px] w-[18px] flex justify-center items-center">
                  <img src={RightIcon} alt="icon" />
                </div>
                {props?.f3}
              </li>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <Button
              disabled={props?.disabled}
              name={props?.btnTxt}
              color={props?.color}
              border={props?.border}
              className="font-extrabold font-nunito-sans text-xl text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
              onClick={() => handleButtonClick(props)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div role="status" className="flex justify-center items-center w-full">
          <svg
            aria-hidden="true"
            className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : errorMessage !== "" ? (
        ""
      ) : (
        <div className="flex flex-col items-center justify-center">
          <div className="relative z-10 flex w-[80%] sm:flex md:flex sm:gap-x-10 gap-x-2 justify-center items-center border rounded-[70px] sm:w-fit  mx-5 h-fit border-[#FF5A82] cursor-pointer">
            {planType?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    selectedPlanType === index
                      ? "bg-[#FF5A82] text-[#fff]"
                      : "border-b-transparent text-[#616161]"
                  } flex sm:py-4 sm:px-8 py-4 px-2 w-[50%]  font-normal font-nunito-sans lg:text-2xl text-xs text-center items-center justify-center sm:text-lg md:text-lg cursor-pointer rounded-[70px]`}
                  onClick={() => setSelectedPlanType(index)}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className="container mt-6 mb-12 mx-auto md:px-6">
            <div>
              <div className="opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block mb-6 flex justify-center items-center">
                <div className="grid gap-3 lg:grid-cols-3 lg:gap-x-12 w-full xl:w-fit ">
                  {plans?.map((item, index) => {
                    if (
                      selectedPlanType === 0 &&
                      item?.["Membership Duration"] === 30
                    ) {
                      return (
                        <PlanCard
                          key={index}
                          featured={index === 1 ? true : false}
                          membershipName={item?.["Membership Name"]}
                          price={item?.["Membership Price"]}
                          duration={item?.["Membership Duration"]}
                          credits={item?.["Membership Credits"]}
                          f1={item?.["Membership Feature 1"]}
                          f2={item?.["Membership Feature 2"]}
                          f3={item?.["Membership Feature 3"]}
                          f4={item?.["Membership Feature 4"]}
                          f5={item?.["Membership Feature 5"]}
                          btnTxt={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString() ? "Current" : (membershipId && membershipId < item?.["Membership ID"] ? "Upgrade" : (!membershipId || membershipId === "null" || membershipId === null ? "Get Started" : "Downgrade"))}
                          disabled={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString()}
                          membership_id={item?.["Membership ID"]}
                          border={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString() ? "grey" : null}
                          color={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString() ? "grey" : null}
                          fromPricing={props?.fromPricing || false}
                        />
                      );
                    } else if (
                      selectedPlanType === 1 &&
                      item?.["Membership Duration"] === 365
                    ) {
                      return (
                        <PlanCard
                          key={index}
                          featured={index === 4 ? true : false}
                          membershipName={item?.["Membership Name"]}
                          price={item?.["Membership Price"]}
                          duration={item?.["Membership Duration"]}
                          credits={item?.["Membership Credits"]}
                          f1={item?.["Membership Feature 1"]}
                          f2={item?.["Membership Feature 2"]}
                          f3={item?.["Membership Feature 3"]}
                          f4={item?.["Membership Feature 4"]}
                          f5={item?.["Membership Feature 5"]}
                          btnTxt={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString() ? "Current" : (membershipId && membershipId < item?.["Membership ID"] ? "Upgrade" : (!membershipId || membershipId === "null" || membershipId === null ? "Get Started" : "Downgrade"))}
                          disabled={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString()}
                          membership_id={item?.["Membership ID"]}
                          border={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString() ? "grey" : null}
                          color={membershipId === item?.["Membership ID"] || membershipId === item?.["Membership ID"].toString() ? "grey" : null}
                          fromPricing={props?.fromPricing || false}

                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Confirm Plan Change</h2>
            <p className="mb-4">Are you sure you want to {selectedPlan.btnTxt.toLowerCase()} your plan?</p>
            <div className="flex justify-end">
              <button
                className="mr-4 px-4 py-2 bg-gray-300 rounded"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanCards;
