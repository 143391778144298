import React from "react";
import BankIcon from "../../assets/bankIcon.svg";

const Drawer = ({ isOpen, setIsOpenDrawer, paymentDetails }) => {
  if (!paymentDetails) return null;

  const statusColorClass = paymentDetails.payment_status === "Paid" ? "text-green-600" : "text-red-600";

  return (
    <>
      <div
        id="drawer-example"
        className={`${
          isOpen ? "translate-x-0" : "translate-x-full"
        } fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white md:w-[50%] lg:w-[35%] w-full border`}
        tabindex="-1"
        aria-labelledby="drawer-label"
      >
        <div className="p-10">
          <button
            className="text-black bg-transparent rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center"
            onClick={() => setIsOpenDrawer(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          <div id="contentToPrint" className="">
            <h5 className="font-nunito-sans font-extrabold text-[26px] text-[#111827] w-full text-center">
              Transaction Details
            </h5>
            <div className="w-full flex flex-col items-center justify-center border-b border-b-[#D9D9D9] mt-10">
              <div
                className="border-[3px] rounded-full p-6"
                style={{
                  border:
                    "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                }}
              >
                <img src={BankIcon} alt="bankIcon" />
              </div>
              <div className="font-nunito-sans font-bold text-[34px] text-[#111827] mt-2">
                ${paymentDetails.total_amount}
              </div>
              <div className={`font-nunito-sans font-semibold text-[22px] mb-4 ${statusColorClass}`}>
                {paymentDetails.payment_status}
              </div>
            </div>
            <div>
              <div className="font-bold bg-gradient-to-r from-orange-400 via-red-500 to-pink-500 text-transparent bg-clip-text font-nunito-sans text-lg my-4">
                Transaction Details
              </div>
              <div className="flex flex-col gap-5">
                <div>
                  <div className="font-nunito-sans font-bold text-lg text-[#000]">
                    Txn ID:
                  </div>
                  <div className="font-nunito-sans font-semibold text-lg text-[#616161]">
                    #{paymentDetails.id}
                  </div>
                </div>
                <div>
                  <div className="font-nunito-sans font-bold text-lg text-[#000]">
                    Payment ID:
                  </div>
                  <div className="font-nunito-sans font-semibold text-lg text-[#616161]" style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                    {paymentDetails.payment_id || "N/A"}
                  </div>
                </div>
                <div>
                  <div className="font-nunito-sans font-bold text-lg text-[#000]">
                    Description:
                  </div>
                  <div className="font-nunito-sans font-semibold text-lg text-[#616161]">
                    {paymentDetails.description || "Payment Mode - Stripe"}
                  </div>
                </div>
                <div>
                  <div className="font-nunito-sans font-bold text-lg text-[#000]">
                    Completed:
                  </div>
                  <div className="font-nunito-sans font-semibold text-lg text-[#616161]">
                    {new Date(paymentDetails.date_time).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        id="ifmcontentstoprint"
        className="h-0 w-0 absolute"
      ></iframe>
    </>
  );
};

export default Drawer;
