import React from "react";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const verified = JSON.parse(localStorage.getItem("Verified"));

  const handleRout = (rout) => {
    navigate(`${rout}`);
  };

  const generateReport = () => {
    const divToScroll = document.getElementById(
      pathname === "/pricing" ? "FaqPricing" : "FaqHome"
    );
    if (divToScroll) {
      divToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <nav
        className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 w-full min-w-fit mb-2 h-fit"
        style={{
          background: "linear-gradient(to left, #feefe3, #edfbfc)",
        }}
      >
        <div className="flex flex-col sm:flex-col lg:flex-row justify-between items-center  mx-auto my-8 max-w-screen-xl container h-full">
          <div
            className="flex gap-2 items-center justify-center cursor-pointer"
            onClick={() => handleRout("/")}
          >
            <img className="" loading="eager" alt="" src={HeaderIcon} />
            <span className="uppercase font-bold font-nunito-sans lg:text-[32px] text-[22px] sm:text-[22px] md:text-[22px] leading-[21px] ">
              Living Image
            </span>
          </div>
          <div className="flex items-center h-full">
            <div className="justify-between items-center w-full lg:flex lg:w-auto ">
              <ul className="flex flex-col mt-4 font-medium font-nunito-sans lg:flex-row lg:space-x-8 lg:mt-0 items-center">
                <li
                  className="flex items-center text-center"
                  onClick={() => handleRout("/")}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                    <div className="relative leading-[130%]text-center text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                      Home
                    </div>
                  </div>
                </li>
                <li
                  className="flex items-center text-center"
                  onClick={generateReport}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                    <div className="relative leading-[130%] text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                      FAQs
                    </div>
                  </div>
                </li>
                <li
                  className="flex items-center"
                  onClick={() => handleRout("/pricing")}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                    <div className="relative leading-[130%] text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                      Pricing
                    </div>
                  </div>
                </li>
                <li
                  className="flex items-center"
                  onClick={() => handleRout("/")}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                    <div className="relative leading-[130%] text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                      Privacy Policy
                    </div>
                  </div>
                </li>
                <li
                  className="flex items-center"
                  onClick={() => handleRout("/terms-of-service")}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                    <div className="relative leading-[130%] text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                      Terms of Service
                    </div>
                  </div>
                </li>
                {/* {verified !== true && (
                  <li
                    className="flex items-center"
                    onClick={() => handleRout("/register")}
                  >
                    <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                      <div className="relative leading-[130%] text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                        Sign Up
                      </div>
                    </div>
                  </li>
                )}
                {verified !== true && (
                  <li
                    className="flex items-center"
                    onClick={() => handleRout("/login")}
                  >
                    <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                      <div className="relative leading-[130%] text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                        Login
                      </div>
                    </div>
                  </li>
                )} */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Footer;
