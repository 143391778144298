import React, { useEffect, useState } from "react";
import Button from "../../../common/button";
import InformationLineIcon from "../../../assets/informationLineIcon.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  creditBalanceData,
  updateDeashbordImgLibraryState,
  updateImgLibraryTabState,
} from "../../../redux/dashbordSlice/dashbordSlice";
import { useNavigate } from "react-router-dom";
import { logout } from '../../../Utils/Logout';

const UpdateUploadedImageDrawer = ({ isOpen, setIsOpenDrawer, imageData, setHasChanges }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [privacyTab, setPrivacyTab] = useState(["Public", "Private"]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [generateImgError, setGenerateImgError] = useState("");
  const [selectedFrequencyType, setSelectedFrequencyType] = useState("hour");
  const [selectedFrequency, setSelectedFrequency] = useState(1);
  const [prompt, setPrompt] = useState("No Prompt");
  const [tag, setTag] = useState("");
  const [imageName, setImageName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [isLoder, setIsLoder] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [imageError, setImageError] = useState("");
  const [promptError, setPromptError] = useState("");
  const [frequencyError, setFrequencyError] = useState("");

  const creditBalance = useSelector(creditBalanceData);

  const accessToken = localStorage.getItem("AccessToken");

  const handleImageChange = (e) => {
    setSelectedImg(e?.target?.files[0]);
    setHasChanges(true);
  };

  const handleGenerateImgApi = async () => {
    setIsLoder(true);

    const formData = new FormData();
    formData.append("frequency", selectedFrequency);
    formData.append("frequency_type", selectedFrequencyType);
    formData.append("photo", selectedImg !== null && selectedImg);
    formData.append("prompt", prompt);
    formData.append("public", selectedTab === 0 ? "True" : "False");
    formData.append("tag", tag !== "" ? tag : "");
    formData.append(
      "user_image_name",
      imageName ? imageName : selectedImg?.name
    );
    formData.append("description", description !== "" ? description : "");
    formData.append(
      "image_id",
      imageData?.original_image_id !== "" ? imageData?.original_image_id : ""
    );

    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/update/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.status === 200) {
        setSelectedTab(0);
        setGenerateImgError("");
        setSelectedFrequencyType("minute");
        setSelectedFrequency(1);
        setPrompt("");
        setTag("");
        setImageName("");
        setDescription("");
        setSelectedImg(null);
        setIsLoder(false);
        setIsOpenDrawer(false);
        setHasChanges(false); // Reset changes state after successful update
        dispatch(updateDeashbordImgLibraryState(true));
        dispatch(updateImgLibraryTabState(true));
        navigate("/dashboard");
        navigate(0);
      }
    } catch (error) {
      setSelectedTab(0);
      setGenerateImgError("");
      setSelectedFrequencyType("minute");
      setSelectedFrequency(1);
      setPrompt("");
      setTag("");
      setImageName("");
      setDescription("");
      setSelectedImg(null);
      setIsLoder(false);
      setGenerateImgError(error?.response?.data?.Message || "Oh no! An error occured.");
      if (error?.response?.status === 401) {
        logout()
      }
    }
  };

  const handleGenerateImg = () => {
    if (selectedFrequency !== "") {
      handleGenerateImgApi();
    } else {
      setFrequencyError("Please enter the Frequency");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setGenerateImgError("");
    }, 3000);
  }, [generateImgError]);

  const handleDragOver = (e) => {
    setDragging(true);
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    setDragging(false);
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setSelectedImg(droppedFiles[0]);
      setHasChanges(true);
    }
    setDragging(false);
  };

  useEffect(() => {
    setPrompt(imageData?.prompt);
    setSelectedTab(imageData?.public ? 0 : 1);
    setImageName(imageData?.user_image_name);
    setSelectedFrequencyType(imageData?.frequency_type);
    setSelectedFrequency(imageData?.frequency);
    setTag(imageData?.tag);
    setDescription(imageData?.description);
  }, [imageData]);

  return (
    <>
      <div className="flex md:top-0 md:left-0 md:h-screen h-full md:overflow-y-auto md:transition-transform md:z-50 justify-center items-center md:items-start bg-[#fff] w-full" tabindex="-1" aria-labelledby="drawer-label">
        <div className="p-10 flex flex-col gap-5">
          <h5 className="font-nunito-sans font-extrabold lg:text-4xl text-[30px] text-[#000] w-full text-center">
            Edit Image Details
          </h5>
          <div className="flex flex-col w-full mt-5">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Select Type of regeneration:
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div>
              <select
                id="countries"
                className="text-[#616161] outline-none border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-3 px-6 gap-2 mt-2 text-sm w-full"
                onChange={(e) => { setSelectedFrequencyType(e?.target?.value); setHasChanges(true); }}
                value={selectedFrequencyType}
              >
                <option selected value="minute">Minute</option>
                <option value="hour">Hours</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Frequency of regeneration:
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full mt-2">
              <input
                type="number"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                placeholder="1"
                min="1"
                value={selectedFrequency}
                onChange={(e) => { setSelectedFrequency(e?.target?.value); setHasChanges(true); }}
              />
            </div>
          </div>
          {frequencyError || selectedFrequency < 1 && (
            <div className="text-red-700 text-xs">{frequencyError || "Should not be less than 1."}</div>
          )}
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Tag (Optional):
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full mt-2">
              <input
                type="text"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                placeholder="#tag"
                value={tag !== null && tag !== "null" && tag !== undefined && tag !== "undefined" ? tag : "#tag"}
                onChange={(e) => { setTag(e?.target?.value); setHasChanges(true); }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Image Name (Optional):
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full mt-2">
              <input
                type="text"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                placeholder="Image Name"
                value={imageName !== undefined && imageName !== 'undefined' && imageName !== null && imageName !== 'null' ? imageName : "Image Name"}
                onChange={(e) => { setImageName(e?.target?.value); setHasChanges(true); }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Description (Optional):
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="rounded-[80px] flex justify-center items-center py-2 gap-2 w-full mt-2">
              <textarea
                id="message"
                rows="4"
                placeholder="Description"
                value={description}
                className="text-[#616161] outline-none border border-[#CCCCCC] bg-[#FBFBFB] w-full block p-2.5 rounded-lg"
                onChange={(e) => { setDescription(e?.target?.value); setHasChanges(true); }}
              ></textarea>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Privacy:
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="flex justify-start items-center gap-5 mt-2">
              {privacyTab?.map((item, index) => (
                <button
                  key={index}
                  className={`${
                    selectedTab === index
                      ? "text-[#000] border-[2px] border-[#FF5A82]"
                      : "text-[#616161] bg-[#FBFBFB] border border-[#CCCCCC]"
                  } font-nunito-sans font-normal text-lg px-[30px] py-[8px] rounded-full`}
                  onClick={() => { setSelectedTab(index); setHasChanges(true); }}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-center items-center mt-10">
            <div className="flex text-center text-base font-nunito-sans font-semibold gap-2 text-[#000]">
              Credits:
              <span className="text-[#616161] underline">{creditBalance} credits available</span>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col mx-8">
            {isLoder ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Button
                name={"Update Image"}
                className={"font-nunito-sans font-extrabold text-xl lg:text-2xl text-[#000] w-full"}
                onClick={handleGenerateImg}
              />
            )}
            {generateImgError && (
              <div className="text-sm text-red-700 w-full text-center mt-10">
                {generateImgError}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUploadedImageDrawer;
