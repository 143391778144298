import React, { useState } from "react";
import CreditListTable from "./creditListTable";
import BuyCredits from "./buyCredits";
import { creditBalanceData } from "../../../redux/dashbordSlice/dashbordSlice";
import { useSelector } from "react-redux";
import CreditDeductHistory from "../../../components/CreditDeductHistory";

const CreditsList = () => {
  const [creditTeb, setCreditTeb] = useState(["Used", "Added", "Buy More"]);
  const [selectedTeb, setSelectedTeb] = useState(0);
  const creditBalance = useSelector(creditBalanceData);

  return (
    <>
      <div className="w-full border border-[#CCCCCC] rounded-[20px] bg-[#fff] flex px-10 py-8 flex-col gap-7 overflow-auto mb-10">
        <div className="border-b border-b-[#cccccc] flex justify-between items-center w-full">
          <div className="flex md:gap-x-20 gap-x-5 h-full">
            {creditTeb?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    selectedTeb === index ? "border-b-2 border-[#ff5a82]" : "border-none"
                  } lg:text-[22px] text-lg sm:text-lg md:text-lg font-nunito-sans font-bold text-[#000]  cursor-pointer`}
                  onClick={() => setSelectedTeb(index)}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div>
            <div className="hidden sm:flex items-center gap-2 text-[#000] font-nunito-sans font-extrabold lg:text-[28px] text-[10px] sm:text-[10px] md:text-[10px]">
              <span className="text-[#FF5A82]">{creditBalance}</span>Credits
              Available
            </div>
          </div>
        </div>
        <div>{selectedTeb === 0 ? <CreditDeductHistory /> : selectedTeb === 1 ? <CreditListTable /> : <BuyCredits />}</div>
      </div>
    </>
  );
};

export default CreditsList;
