import React from "react";
import { useNavigate } from "react-router-dom";
import crossIcon from "./../assets/cross-icon.png"

const AuthRightCard = (props) => {

   const navigate = useNavigate(); 

  return (
    <div className="bg-[#FF5A82] flex-col items-center relative justify-center py-20 gap-4 md:px-20 px-5 rounded-tr-2xl rounded-br-2xl hidden sm:flex">
      <div
        className="rounded-[50%] bg-slate-50 text-gray-500 absolute top-6 right-6 py-2 px-2 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img src={crossIcon} alt="Close" srcset="" className="mx-1 my-1" />
      </div>
      <div className=" text-white font-nunito-sans text-5xl font-bold">
        {props?.heading}
      </div>
      <div className="font-nunito-sans text-white text-center">
        {props?.paragraph}
      </div>
      <div>
        <div className="font-nunito-sans font-semibold text-xl text-[#000] mt-6">
          {props?.subtext}
        </div>
        <div
          className="font-nunito-sans font-bold text-2xl cursor-pointer text-center text-white rounded-[3rem] py-1 px-4 mt-1 underline"
          onClick={() => navigate(props?.buttonUrl)}
        >
          {props?.buttonTxt}
        </div>
      </div>
    </div>
  );
};

export default AuthRightCard;
