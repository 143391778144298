import React, { useEffect, useState } from "react";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import Button from "../../common/button";
import FacebookIcon from "../../assets/facebookIcon.svg";
import GoogleIcon from "../../assets/googleIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import AuthRightCard from "../../components/AuthRightCard";
import { logout } from "../../Utils/Logout";
import queryString from "query-string";
import crossIcon from "./../../assets/cross-icon.png";


const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const { upload, prompt, promptText } = queryString.parse(location.search);

  const initialvalue = {
    email: "",
    password: "",
  };

  const [loginData, setLoginData] = useState(initialvalue);
  const [isLoder, setIsLoder] = useState(false);
  const [mailErrorMsg, setMailErrorMsg] = useState("");
  const [PasswordErrorMsg, setPasswordErrorMsg] = useState("");
  const [erroeMsg, setErroeMsg] = useState("");

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e?.target?.name]: e?.target?.value });
  };

  const signinApiCall = async (data) => {
    setIsLoder(true);
    const url = "https://api.livingimage.io/api/login/";
    const header = {
      "Content-Type": "application/json",
    };
    try {
      await axios.post(url, data, header).then((res) => {
        console.log("RESPONSE RECEIVED: ", res, res?.status);
        localStorage.setItem("EmailAddress", loginData.email)
        if (res?.status === 200) {
          if (res?.data?.Message === "Verify your account First!") {
            navigate(`/otp?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`);
          } else {
            setLoginData(initialvalue);
            localStorage.setItem("AuthData", JSON.stringify(res?.data));
            localStorage.setItem(
              "Verified",
              res?.data?.verified ? res?.data?.verified : false
            );
            localStorage.setItem("AccessToken", res?.data?.token?.access);
            localStorage.setItem(
              "SubscriptionStatus",
              res?.data?.subscription_status
            );
            localStorage.setItem("Membership", res?.data?.membership);
            localStorage.setItem(
              "MembershipExpiry",
              res?.data?.membership_expiry_date
            );
            localStorage.setItem(
              "StripeCustomerId",
              res?.data?.stripe_customer_id
            );
            localStorage.setItem("MembershipId", res?.data?.membership_id);

            navigate(`/dashboard?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`);
          }
          setIsLoder(false);
        }
      });
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
      setIsLoder(false);
      setLoginData(initialvalue);
      localStorage.setItem("Verified", false);
      setErroeMsg(err?.response?.data?.Message);
      if (err?.response?.status === 401) {
        logout();
      }
    }
  };

  const handleSignIn = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (
      loginData?.email === "" ||
      loginData?.password === "" ||
      !emailRegex?.test(loginData?.email)
    ) {
      if (loginData?.email === "") {
        setMailErrorMsg("Please enter email");
      } else if (emailRegex?.test(loginData?.email)) {
        setMailErrorMsg("");
      } else {
        setMailErrorMsg("Please enter valid email");
      }

      if (loginData?.password === "") {
        setPasswordErrorMsg("Please enter password");
      } else {
        setPasswordErrorMsg("");
      }
    } else if (loginData?.email !== "" && loginData?.password !== "") {
      signinApiCall(loginData);
    }
  };

  useEffect(() => {
    if (loginData?.email === "") {
      setMailErrorMsg("");
    }
    if (loginData?.password === "") {
      setPasswordErrorMsg("");
    }
  }, [loginData]);

  useEffect(() => {
    setTimeout(() => {
      setErroeMsg("");
    }, 3000);
  }, [erroeMsg]);

  const verified = JSON.parse(localStorage.getItem("Verified"));

  useEffect(() => {
    if (verified === true) {
      navigate(`/dashboard?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`);
    }
  }, [verified, upload, prompt, promptText, navigate]);

  return (
    <>
      <div
        className="w-full flex justify-center items-center h-screen"
        style={{
          background: "linear-gradient(to right top, white 40%, #b7f0f2)",
        }}
      >
        <div className="relative min:w-[640px] w-full grid grid-cols-1 sm:grid-cols-2 m-5 md:w-[75%] max my-36 rounded-2xl bg-[#fff] min-h-[80vh]">

        <div
          className="rounded-[50%] bg-[#f84343] text-gray-500 absolute sm:hidden  top-6 right-6 py-2 px-2 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={crossIcon} alt="Close" srcset="" className="mx-1 my-1" />
        </div>

          <div className="flex flex-col items-center justify-center gap-4 py-20 md:mx-20 mx-5 ">
            <div className="flex gap-2 items-center justify-center w-full">
              <img
                className="lg:w-[45px] lg:h-[45px]  w-[45px] h-[45px]  sm:w-[45px] sm:h-[45px]  md:w-[45px] md:h-[45px] "
                loading="eager"
                alt=""
                src={HeaderIcon}
              />
              <span className="uppercase font-bold flex flex-col font-nunito-sans lg:text-[32px] text-base sm:text-base md:text-base leading-[21px] ">
                LivingImage
              </span>
            </div>
            <div
              className="bg-gradient-to-r from-emerald-500 via-cyan-400 to-sky-500 font-nunito-sans font-extrabold text-3xl mt-4"
              style={{ backgroundClip: "text", color: "transparent" }}
            >
              Sign In
            </div>
            <div className="flex flex-col gap-2 w-full">
              <input
                type="email"
                placeholder="E-Mail"
                onChange={handleChange}
                value={loginData?.email}
                name="email"
                className="font-nunito-sans font-normal text-2xl text-[#616161] w-full border border-[#CCCCCC] bg-[#FBFBFB] py-3 px-8 outline-none rounded-lg"
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {mailErrorMsg}
              </div>
              <input
                type="password"
                placeholder="Password"
                onChange={handleChange}
                value={loginData?.password}
                name="password"
                className="font-nunito-sans font-normal text-2xl text-[#616161] w-full border border-[#CCCCCC] bg-[#FBFBFB] py-3 px-8 outline-none rounded-lg"
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {PasswordErrorMsg}
              </div>
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{erroeMsg}</div>
            <div className="w-full flex justify-center items-center">
              {isLoder ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button
                  name={"Sign in"}
                  className={"w-full"}
                  onClick={handleSignIn}
                />
              )}
            </div>
            <div
              className="font-nunito-sans font-semibold text-xl text-[#616161] cursor-pointer"
              onClick={() => navigate(`/forgotpassword?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`)}
            >
              Forgot your password?
            </div>

            <div className="text-center visible sm:hidden">
            <div className="font-nunito-sans text-2xl">Don't have an account?</div>
            <a
              href={`/register?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`}
              className="text-2xl underline font-nunito-sans"
            >
              Sign Up
            </a>
          </div>
          </div>

          <AuthRightCard
            heading="Welcome back!"
            paragraph="Please enter your email and password to login to your account and start generating images with AI using your artistic images or prompts."
            subtext="Don't have an account?"
            buttonTxt="Sign up"
            buttonUrl={`/register?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`}
          />
        </div>
      </div>
    </>
  );
};

export default SignIn;
