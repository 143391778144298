import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const usePrompt = (when) => {
  const navigate = useNavigate();
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && when) {
      setIsPromptOpen(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setIsPromptOpen(false);
    setConfirmedNavigation(true);
    
  };

  const handleCancelNavigationClick = () => {
    setIsPromptOpen(false);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
    if (confirmedNavigation){
        navigate(-1)
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return {
    isPromptOpen,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    handleCancelNavigationClick,
  };
};

export default usePrompt;
