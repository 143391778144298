import React, { useState, useEffect } from "react";
import RightArrowIcon from "../../../assets/rightArrowIcon.svg";
import Drawer from "../../../components/drawer";
import axios from "axios";
import { logout } from "../../../Utils/Logout";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import "@fortawesome/fontawesome-free/css/all.min.css";

const PaymentList = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "date_time", direction: "descending" });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const accessToken = localStorage.getItem("AccessToken");

  const getPaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get_payment_history/",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        if (response.data.Payment_History) {
          const sortedPayments = response.data.Payment_History.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
          setPaymentDetails(sortedPayments);
          setFilteredDetails(sortedPayments);
        } else {
          setErrorMessage(response.data.Message || "No Payment History Found");
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.response?.data?.Message || error?.message);
      if (error?.response?.status === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  useEffect(() => {
    const filtered = paymentDetails.filter((payment) =>
      Object.values(payment).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredDetails(filtered);
  }, [searchQuery, paymentDetails]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredDetails].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredDetails(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return "fa-sort";
    }
    if (sortConfig.direction === "ascending") {
      return "fa-sort-up";
    }
    return "fa-sort-down";
  };

  const openDrawerWithPaymentDetails = (payment) => {
    setSelectedPayment(payment);
    setIsOpenDrawer(true);
  };

  return (
    <>
      <div className="w-full border border-[#CCCCCC] rounded-[20px] bg-[#fff] flex flex-col gap-7 overflow-auto p-8">
        <div>
          <div className="font-nunito-sans font-extrabold text-[28px] text-[#000]">
            Payments
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search payment"
            className="mt-4 p-2 border border-[#CCCCCC] rounded"
          />
        </div>
        <div className="h-full w-full overflow-auto">
          {loading ? (
            <div className="text-center py-6">Loading...</div>
          ) : filteredDetails.length > 0 ? (
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="border-y border-y-[#CCCCCC]">
                  <th className="px-6 py-4 cursor-pointer" onClick={() => handleSort("id")}>
                    ID <i className={`fas ${getSortIcon("id")}`}></i>
                  </th>
                  <th className="px-6 py-4 cursor-pointer" onClick={() => handleSort("date_time")}>
                    Date <i className={`fas ${getSortIcon("date_time")}`}></i>
                  </th>
                  <th className="px-6 py-4 cursor-pointer" onClick={() => handleSort("payment_mode")}>
                    Mode <i className={`fas ${getSortIcon("payment_mode")}`}></i>
                  </th>
                  <th className="px-6 py-4 cursor-pointer" onClick={() => handleSort("payment_status")}>
                    Status <i className={`fas ${getSortIcon("payment_status")}`}></i>
                  </th>
                  <th className="px-6 py-4 cursor-pointer" onClick={() => handleSort("total_amount")}>
                    Amount <i className={`fas ${getSortIcon("total_amount")}`}></i>
                  </th>
                  <th className="px-6 py-4">Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredDetails.map((payment, index) => (
                  <tr key={index} className="border-y border-y-[#CCCCCC]">
                    <td className="px-6 py-4 text-center">{payment.id}</td>
                    <td className="px-6 py-4 text-center">
                      {new Date(payment.date_time).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 text-center">{payment.payment_mode}</td>
                    <td className={`px-6 py-4 text-center ${payment.payment_status === "Paid" ? "text-green-500" : payment.payment_status === "Failed" ? "text-red-500" : "text-black"}`}>
                      {payment.payment_status}
                    </td>
                    <td className="px-6 py-4 text-center font-bold">${payment.total_amount}</td>
                    <td className="px-6 py-4 text-center cursor-pointer flex items-center justify-center" onClick={() => openDrawerWithPaymentDetails(payment)}>
                      <img src={RightArrowIcon} alt="Arrow Icon" className="text-center" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center py-6">{errorMessage || "No payment details available"}</div>
          )}
        </div>
      </div>
      {isOpenDrawer && (
        <div className="w-full">
          <Drawer isOpen={isOpenDrawer} setIsOpenDrawer={setIsOpenDrawer} paymentDetails={selectedPayment} />
        </div>
      )}
    </>
  );
};

export default PaymentList;
