import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { logout } from "../Utils/Logout";
import "react-datepicker/dist/react-datepicker.css";
import {
  parse,
  startOfWeek,
  format,
  sub,
  startOfMonth,
  startOfYear,
  startOfHour,
  subHours,
} from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CreditDeductHistory = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [creditDetails, setCreditDetails] = useState([]);
  const [frequency, setFrequency] = useState("day");
  const [frequencyNumber, setFrequencyNumber] = useState(12);

  const accessToken = localStorage.getItem("AccessToken");

  const getCreditDeductHistory = async () => {
    try {
      setLoading(true);

      const dataPayload = {
        transaction_type: "Credit Deduction",
        date_filter: frequency === "hour" ? "day" : frequency,
        frequency: frequency === "hour" ? 1 : frequencyNumber,
      };

      const response = await axios.post(
        "https://api.livingimage.io/api/get_credit_detail/",
        dataPayload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response?.status === 200) {
        setCreditDetails(response?.data?.Credit_details);
      }
    } catch (error) {
      setError(error);
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCreditDeductHistory();
  }, [frequency, frequencyNumber]);

  const getStartDate = (currentDate, frequency, index) => {
    if (frequency === "week") {
      return startOfWeek(sub(currentDate, { weeks: index }), { weekStartsOn: 1 });
    } else if (frequency === "month") {
      return startOfMonth(sub(currentDate, { months: index }));
    } else if (frequency === "year") {
      return startOfYear(sub(currentDate, { years: index }));
    } else if (frequency === "hour") {
      return startOfHour(sub(currentDate, { hours: index }));
    } else {
      return sub(currentDate, { days: index });
    }
  };

  const getLabelFormat = (frequency) => {
    if (frequency === "week") {
      return "yyyy-MM-dd";
    } else if (frequency === "month") {
      return "yyyy-MM";
    } else if (frequency === "year") {
      return "yyyy";
    } else if (frequency === "hour") {
      return "yyyy-MM-dd HH:00";
    } else {
      return "yyyy-MM-dd";
    }
  };

  const aggregateData = (data, frequency) => {
    const aggregated = {};
    const now = new Date();

    // Initialize all periods to 0
    for (let i = 0; i < frequencyNumber; i++) {
      const periodStart = getStartDate(now, frequency, i);
      const periodLabel = format(periodStart, getLabelFormat(frequency));
      aggregated[periodLabel] = 0;
    }

    // Aggregate the data
    data.forEach((detail) => {
      const date = parse(detail["CreditHistory Date Time"], "dd/MM/yyyy HH:mm:ss", new Date());
      let periodStart;

      if (frequency === "week") {
        periodStart = startOfWeek(date, { weekStartsOn: 1 });
      } else if (frequency === "month") {
        periodStart = startOfMonth(date);
      } else if (frequency === "year") {
        periodStart = startOfYear(date);
      } else if (frequency === "hour") {
        periodStart = startOfHour(date);
      } else {
        periodStart = date;
      }

      const periodLabel = format(periodStart, getLabelFormat(frequency));
      if (aggregated[periodLabel] !== undefined) {
        aggregated[periodLabel] += detail["CreditHistory Credits"];
      }
    });

    // Remove periods older than the frequencyNumber for hours
    if (frequency === "hour") {
      const earliestPeriod = format(subHours(now, frequencyNumber - 1), getLabelFormat(frequency));
      Object.keys(aggregated).forEach((key) => {
        if (new Date(key) < new Date(earliestPeriod)) {
          delete aggregated[key];
        }
      });
    }

    return Object.keys(aggregated)
      .sort((a, b) => new Date(a) - new Date(b))
      .reduce((obj, key) => {
        obj[key] = aggregated[key];
        return obj;
      }, {});
  };

  const aggregatedData = aggregateData(creditDetails, frequency);

  const labels = Object.keys(aggregatedData);
  const data = {
    labels,
    datasets: [
      {
        label: "Credits",
        data: Object.values(aggregatedData),
        backgroundColor: "#ff5a8270",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Credit Deduction History",
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Date Time",
        },
      },
      y: {
        title: {
          display: true,
          text: "Credits",
        },
        beginAtZero: true,
      },
    },
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="flex items-center gap-2 border-2 rounded-md p-2 w-full md:w-[max-content]">
        <span>Last</span>
        <select
          className="px-4 py-2 mx-2"
          id="frequencyNumber"
          value={frequencyNumber}
          onChange={(e) => setFrequencyNumber(parseInt(e.target.value))}
        >
          <option value="6">6</option>
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="36">36</option>
        </select>
        <select
          className="px-4 py-2 mx-2"
          id="frequency"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
        >
          <option value="hour">hours</option>
          <option value="day">days</option>
          <option value="week">weeks</option>
          <option value="month">months</option>
          {/* <option value="year">years</option> */}
        </select>
      </div>
      <div className="w-full h-96 md:w-3/4">
        <Bar options={options} data={data} />
      </div>
    </>
  );
};

export default CreditDeductHistory;
