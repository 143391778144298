import React, { useEffect, useState } from "react";
import Button from "../../common/button";
import SearchIcon from "../../assets/searchIcon.svg";
import DownIcon from "../../assets/downIcon.svg";
import AvtarImg from "../../assets/avtarImg.png";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import UploadImgDrawer from "../uploadImgDrawer";
import ProfileIcon from "../../assets/profileIcon.png";
import axios from "axios";
import { isProfileImageData } from "../../redux/dashbordSlice/dashbordSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { logout } from '../../Utils/Logout'
import { useNavigate } from "react-router-dom";
import {
  creditBalanceData,
} from "./../../redux/dashbordSlice/dashbordSlice";

const DashboardHeader = () => {

  const creditBalance = useSelector(creditBalanceData);

  const accessToken = localStorage.getItem("AccessToken");

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const upload = searchParams.get('upload');
  const profileImgUrl = searchParams.get('profileImage');


  const navigate = useNavigate();

  const [profileImg, setProfileImg] = useState(profileImgUrl ? profileImgUrl : null);
  const [profileData, setProfileData] = useState();
  const [profileError, setProfileError] = useState("");



  var count = 0;

  const getProfileData = async () => {
    try {
      const response = await axios.get(
        "https://api.livingimage.io/api/profile/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        setProfileData(response.data?.personal_data);
        //console.log(response.data, response);
        //console.log(response.data?.personal_data)
      }
    } catch (error) {
      //console.log("Axios Error: ", error);
      setProfileError(error?.response?.data?.errors?.detail);
      if (error?.response?.status === 401) {
        logout()
      }
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);


  const getProfileImage = async () => {


    // setIsLoderGetProfile(true);
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-profile-pic/",
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 401) {
        logout()
      }
      if (response?.status === 200) {
        setProfileImg(response?.data?.Profile_Picture ? response?.data?.Profile_Picture + "?" + new Date().getTime() : null);
        //console.log(response.data, response);
        // setIsLoderGetProfile(false);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logout()
      }

      //console.log("Axios Error: ", error);
      // setIsLoderGetProfile(false);
      // setProfileError(error?.response?.data?.errors?.detail);
    }
  };
  const isProfileImageDataValue = useSelector(isProfileImageData);

  useEffect(() => {
    getProfileImage();

  }, [isProfileImageDataValue]);

  return (
    <>
      <div className="flex flex-col gap-y-5">
        <div className="w-full lg:border lg:border-[#CCCCCC] rounded-[20px] bg-[#FBFBFB] lg:bg-white flex px-10 py-4 justify-end lg:justify-between items-center">
          <div className="lg:flex hidden">
            {/* <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center p-2 gap-2">
              <input
                type="text"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB]"
                placeholder="Search"
              />
              <img src={SearchIcon} alt="img" />
            </div> */}
            <span 
            className="font-bold font-nunito-sans lg:text-3xl text-xs text-black"
            style={{textTransform:'capitalize'}}
            >
              Hello {profileData?.name ? profileData.name : ''},
            </span>
          </div>
          <div className="md:gap-10 block sm:flex items-center">
            <div className="flex gap-3 items-center justify-center lg:hidden">
              <img
                className="h-[21px] w-[21px]"
                loading="eager"
                alt=""
                src={HeaderIcon}
              //   onClick={() => handleRout("/")}
              />
              <span className="uppercase font-bold font-nunito-sans text-[24px] text-center leading-[21px]">
                Living Image
              </span>
            </div>
            <div className="flex justify-center items-center">
              <Button
                name={"Upload Image"}
                className="m-2 font-extrabold font-nunito-sans lg:text-xl text-xs text-black rounded-[120px] px-[20px] py-[8px] lg:px-[40px] lg:py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
                onClick={() => setIsOpenDrawer(true)}
                style={{borderColor:"f04e8d"}}
              />
              <Button
                name={"Image from Prompt"}
                className="hidden sm:block m-2 font-extrabold font-nunito-sans lg:text-xl text-xs text-black rounded-[120px] px-[20px] py-[8px] lg:px-[40px] lg:py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
                onClick={() => navigate("/dashboard?upload=true&prompt=true")}
                style={{borderColor:"#20C8D8"}}
              />
            </div>
            <div className="gap-5 hidden md:flex cursor-pointer" onClick={() => navigate('/settings')}>
              {profileImg !== null ? (
                <img
                  src={profileImg}
                  alt="Profile"
                  className="w-[52px] h-[52px] rounded-full"
                />
              ) : (
                <img
                  src={ProfileIcon}
                  alt="Profile"
                  className="w-[52px] h-[52px] rounded-full"
                />
              )}
              {/* <img src={DownIcon} alt="Img" /> */}
            </div>
          </div>
        </div>
        {/* <div className="lg:hidden flex w-full">
          <div className=" w-full bg-[#FBFBFB] rounded-[80px] flex justify-center items-center p-2 gap-2">
            <input
              type="text"
              className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
              placeholder="Search"
            />
            <img src={SearchIcon} alt="img" />
          </div>

        </div> */}
      </div>
      <div className={`mt-5 flex items-center p-4 border border-[#cccccc] rounded-[10px] ${localStorage.getItem("SubscriptionStatus") === "false" ||  localStorage.getItem("SubscriptionStatus") === false || !localStorage.getItem("SubscriptionStatus") ? '' : 'hidden'}`} >

        <button
          onClick={() => navigate('/upgrade')}
          className="ml-2 px-3 py-1 bg-[#ff5a82] text-white font-semibold rounded-md hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50"
        >
          Upgrade now
        </button>
        <span className="text-pink-700 font-medium ml-3">
        You don't have a membership plan. {creditBalance && <span> You are just having <b>{creditBalance}</b> credits left.</ span>}
        </span>
      </div>
      <div className="w-full">
        <UploadImgDrawer
          isOpen={isOpenDrawer || (upload == 'true' || upload == true ? true : false)}
          setIsOpenDrawer={setIsOpenDrawer}
        />
      </div>
    </>
  );
};

export default DashboardHeader;
