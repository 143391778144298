import React, { useState } from "react";
import MultiDot from "../../../assets/multiDot.svg";

const Faq = () => {
  const [faq, setFaq] = useState([
    {
      que: "How does Living Image work?",
      ans: "Living Image uses AI and your desired update frequency to work together to bring images to life.",
    },
    {
      que: "How much does Living Image AI cost?",
      ans: "Currently for each Standard definition AI update is 0.5 cents and every HD update is 10 cents USD.",
    },
    {
      que: "How often can I have my image dynamically update.",
      ans: "Living Image can turn your static image into a dynamic image every minute, hour, day, week, month, or year. You have full control on how often you would like your image to update.",
    },
    {
      que: "Is the payment service secure?",
      ans: "Yes, we use STRIPE for our payment processing.",
    },
    {
      que: "Can I try Living Image for free?",
      ans: "Yes! By signing up for free, you will get 100 credits for free! This will allow you to update an image 100 times at no cost to you.",
    },
  ]);

  return (
    <>
      <div className="w-full">
        <h1
          className="relative z-10 font-extrabold bg-gradient-to-r from-orange-400 via-red-500 to-pink-500 text-transparent bg-clip-text font-nunito-sans lg:text-5xl text-2xl sm:text-2xl md:text-2xl text-black text-center"
          // style={{
          //   background:
          //     // "linear-gradient(270deg, #FB4EA1 40.5%, #FF5A82 57.5%, #FFA439 100%)",
          //     "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
          //   backgroundClip: "text",
          //   color: "transparent",
          // }}
        >
          Frequent asked questions
        </h1>
        <div className="relative w-full bg-white px-6 pb-8 overflow-x-clip">
          <div className="flex justify-center items-center absolute w-full top-[-50%] ">
            <img src={MultiDot} alt="img" />
          </div>
          <div className="mx-auto bg-white">
            <div className="mx-auto mt-8 md:w-[70%] w-full  flex flex-col gap-5 z-10 relative bg-white">
              {faq?.map((item, index) => {
                return (
                  <div className="p-5  border border-[#CCCCCC] " key={index}>
                    <details className="group">
                      <summary className="flex cursor-pointer list-none items-center justify-between font-medium font-nunito-sans">
                        <span> {item?.que}</span>
                        <span className="transition group-open:rotate-180">
                          <svg
                            fill="none"
                            height="24"
                            shape-rendering="geometricPrecision"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </summary>
                      <p className="group-open:animate-fadeIn mt-3 text-neutral-600">
                        {item?.ans}
                      </p>
                    </details>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
