import React, { useEffect } from "react";
import ITextIcon from "../../../assets/iTextIcon.svg";
import ArrowRepeatIcon from "../../../assets/arrowRepeatIcon.svg";
import { useState } from "react";
import axios from "axios";
import { logout } from "../../../Utils/Logout";
import { useNavigate } from "react-router-dom";

const BuyCredits = () => {
  const [creditsToBuy, setCreditsToBuy] = useState(0);
  const [creditPricing, setCreditPricing] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("AccessToken");

  const getCreditPricing = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        "https://api.livingimage.io/api/credit-pricing/",
        {},
        {}
      );

      if (response?.status === 200) {
        setCreditPricing(response?.data?.["Credit Pricing"]?.price);
      }
    } catch (error) {
      setError(error);
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCreditPricing();
  }, []);

  return (
    <>
      {loading && error !== "" ? (
        <div>{error}</div>
      ) : loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div>
            <h1 className="font-nunito-sans font-black text-xl lg:text-[28px] text-[#000]">
              Buy Credits
            </h1>
            <div className="flex justify-center gap-2 mt-5 border-b border-b-[#CCCCCC] pb-5">
              <img
                src={ITextIcon}
                alt="Img"
                className="bg-black  rounded-full py-1 px-2 h-fit w-fit"
              />
              <p className="text-lg lg:text-base font-nunito-sans font-normal text-[#616161]">
                Purchase credits and use them to show your artistic creativity
                by generating futuristic images by writing exemplery prompts or
                by uploading your artistic image.
              </p>
            </div>
          </div>
          {/* <div className="flex justify-between items-center my-5">
            <div className="flex justify-center items-center gap-2 text-lg lg:text-xl font-nunito-sans font-black text-[#000]">
              <span>
                {" "}
                <img
                  src={ArrowRepeatIcon}
                  alt="Img"
                  // className="bg-black  rounded-full py-1 px-2 h-fit w-fit"
                />
              </span>
              Auto Fill Credits
            </div>
            <div>
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div> */}
          <div>
            <div className="bg-[#FBFBFB] border border-[#CCCCCC] rounded-[9px] flex lg:flex-row flex-col justify-between lg:items-center items-start w-full gap-2 p-5 my-10">
              <div className="flex flex-col sm:flex-row justify-between items-center w-full lg:w-[70%] px-4">
                <input
                  className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313] border-2 py-2 px-2 mt-0 mb-0 rounded-[9px]"
                  placeholder="Enter Credits to buy"
                  type="number"
                  onChange={(e) => setCreditsToBuy(e.target.value)}
                />
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                  ${creditPricing} /credit
                </div>
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313] flex items-center gap-1">
                  ${creditsToBuy * creditPricing}
                  <span className="font-nunito-sans font-bold text-sm lg:text-[15px] text-[#7A7A7A]">
                    Total
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <button
                  className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-8 rounded-full h-fit w-fit"
                  onClick={() =>
                    creditsToBuy === 0
                      ? {}
                      : navigate(`/checkout?type=buy&credits=${creditsToBuy}`)
                  }
                  disabled={creditsToBuy === 0}
                >
                  Buy {creditsToBuy} credits
                </button>
                {/* <div className="font-nunito-sans font-bold text-sm lg:text-base text-[#1B1B1B] flex gap-2 items-center">
                <span>
                  <input type="checkbox" />
                </span>
                Auto top-up next time
              </div> */}
              </div>
            </div>
          </div>
          <div>
            <span>OR</span>
            <div className="bg-[#FBFBFB] border border-[#CCCCCC] rounded-[9px] flex lg:flex-row flex-col justify-between lg:items-center items-start gap-2 w-full p-5 my-5 relative">
              {/* <div className="font-nunito-sans font-black text-[15px] text-[#fff] bg-[#1D1D1D] py-1 px-4 rounded-full h-fit w-fit bg-gradient-to-r from-emerald-400 via-teal-400 to-cyan-400 absolute top-[-20%]">
              5% OFF
            </div> */}
              <div className="flex flex-col sm:flex-row justify-between items-start w-full lg:w-[70%] px-4">
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                  100 Credits
                </div>
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                  ${creditPricing} /credit
                </div>
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313] flex items-center gap-1">
                  ${creditPricing * 100}
                  <span className="font-nunito-sans font-bold text-sm lg:text-[15px] text-[#7A7A7A]">
                    Total
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <button
                  className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-8 rounded-full h-fit w-fit"
                  onClick={() => navigate(`/checkout?type=buy&credits=100`)}
                >
                  Buy 100 credits
                </button>
              </div>
            </div>
            <div className="bg-[#FBFBFB] border border-[#CCCCCC] rounded-[9px] flex lg:flex-row flex-col justify-between lg:items-center items-start gap-2 w-full p-5 my-5 relative">
              {/* <div className="font-nunito-sans font-black text-[15px] text-[#fff] bg-[#1D1D1D] py-1 px-4 rounded-full h-fit w-fit bg-gradient-to-r from-emerald-400 via-teal-400 to-cyan-400 absolute top-[-20%]">
              5% OFF
            </div> */}
              <div className="flex flex-col sm:flex-row justify-between items-start w-full lg:w-[70%] px-4">
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                  300 Credits
                </div>
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                  ${creditPricing} /credit
                </div>
                <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313] flex items-center gap-1">
                  ${creditPricing * 300}
                  <span className="font-nunito-sans font-bold text-sm lg:text-[15px] text-[#7A7A7A]">
                    Total
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <button
                  className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-8 rounded-full h-fit w-fit"
                  onClick={() => navigate(`/checkout?type=buy&credits=300`)}
                >
                  Buy 300 credits
                </button>
              </div>
            </div>
          </div>
          {/* <div>
          <h1 className="font-nunito-sans font-black text-xl lg:text-[28px] text-[#000] mt-10">
            Saved Card
          </h1>
        </div>
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-start w-full lg:w-[70%] px-9 py-5">
            <div className="font-nunito-sans lg:font-extrabold font-bold text-lg lg:text-xl text-[#131313]">
              Card name
            </div>
            <div className="font-nunito-sans lg:font-extrabold font-bold text-lg lg:text-xl text-[#131313]">
              Card number
            </div>
            <div className="font-nunito-sans lg:font-extrabold font-bold text-lg lg:text-xl text-[#131313]">
              Expiry date
            </div>
          </div>
          <div className="bg-[#FBFBFB] border border-[#CCCCCC] rounded-[9px] flex lg:flex-row flex-col justify-between lg:items-center items-start  gap-2 w-full p-5">
            <div className="flex flex-col sm:flex-row justify-between items-start w-full lg:w-[70%] px-4">
              <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                John Doe
              </div>
              <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                533 456 5965 245
              </div>
              <div className="font-nunito-sans font-bold text-base lg:text-lg text-[#131313]">
                02/25
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <button className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-8 rounded-full h-fit w-fit">
                Detach
              </button>
            </div>
          </div>
        </div> */}
        </div>
      )}
    </>
  );
};

export default BuyCredits;
