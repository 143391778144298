import React from "react";
import Button from "../../common/button";

const OrderFrameDrawer = ({ isOpen, setIsOpenDrawer, displayFrame }) => {
  return (
    <>
      <div
        id="drawer-example"
        className={`${
          isOpen ? "translate-x-0" : "translate-x-full"
        } fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white md:w-[50%] lg:w-[35%] w-full border`}
        // className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800"
        tabindex="-1"
        aria-labelledby="drawer-label"
      >
        <div className="p-10">
          <button
            className="text-black bg-transparent  rounded-lg text-sm w-8 h-8 absolute end-2.5 flex items-center justify-center"
            // onClick={() => setIsOpenDrawer(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          <div id="contentToPrint" className="">
            <h5 className="font-nunito-sans font-extrabold text-[26px] text-[#111827] w-full text-start">
              {displayFrame === 1 || displayFrame === 0
                ? "Order Frame"
                : "Order placed"}
            </h5>
            {(displayFrame === 1 || displayFrame === 0) && (
              <div className="w-full flex flex-col items-start justify-center border-b border-b-[#D9D9D9] mt-10">
                <div className="flex items-start gap-5 pb-8">
                  <div className="h-[124px] w-[121px] rounded-lg bg-[#000000]">
                    {/* <img /> */}
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-nunito-sans font-extrabold text-[34px] text-[#FB4EA1]">
                      $2.5
                    </div>
                    <div className="font-nunito-sans font-bold text-xl text-[#000000]">
                      Paris 20234
                    </div>
                    <div className="font-nunito-sans font-semibold text-xl text-[#616161]">
                      3inch x 4.5inch
                    </div>
                  </div>
                </div>
              </div>
            )}
            {displayFrame === 2 && (
              <div className="border-t border-t-[#D9D9D9] pt-4 mt-8 flex flex-col gap-8">
                <div className="font-nunito-sans font-extrabold text-[26px] text-[#111827]">
                  Congratulations!
                </div>
                <div className="font-nunito-sans text-xl font-bold text-[#000] flex flex-col gap-1">
                  Order ID
                  <span className="font-semibold text-[#616161]">77845741</span>
                </div>
                <div className="font-nunito-sans text-xl font-bold text-[#000] flex flex-col gap-1">
                  Estimated Delivery:
                  <span className="font-semibold text-[#616161]">
                    01-03 March, 2024
                  </span>
                </div>
              </div>
            )}
            <div>
              <div className="font-extrabold bg-gradient-to-r from-orange-400 via-red-500 to-pink-500 text-transparent bg-clip-text font-nunito-sans text-xl my-4">
                {displayFrame === 0
                  ? "Order Details"
                  : displayFrame === 1
                  ? "Payment Details"
                  : ""}
              </div>
              {displayFrame === 0 && (
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col w-full mt-5">
                    <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
                      Size:
                      <span>
                        {/* <img src={InformationLineIcon} alt="img" /> */}
                      </span>
                    </label>
                    <div
                    // className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-3 px-6 gap-2 w-full mt-2"
                    >
                      <select
                        id="countries"
                        className="text-[#616161] outline-none border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 mt-2  text-xl font-nunito-sans font-semibold w-full"
                        //   onChange={(e) =>
                        //     setSelectedFrequencyType(e?.target?.value)
                        //   }
                        //   value={selectedFrequencyType}
                      >
                        {/* <option selected>Select frequency</option> */}
                        <option selected value="3inch x 4.5inch">
                          3inch x 4.5inch
                        </option>
                        <option value="hour">3inch x 4.5inch</option>
                        <option value="day">3inch x 4.5inch</option>
                        <option value="week">3inch x 4.5inch</option>
                        <option value="month">3inch x 4.5inch</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
                      Name:
                    </label>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-2 text-xl font-nunito-sans font-semibold">
                      <input
                        type="text"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="Jon Ray"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
                      Contact:
                    </label>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-2 text-xl font-nunito-sans font-semibold">
                      <input
                        type="number"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="+44 987 7774"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
                      Email:
                    </label>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-2 text-xl font-nunito-sans font-semibold">
                      <input
                        type="email"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="jon@comapany.xyz"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
                      Address
                    </label>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-3 text-xl font-nunito-sans font-semibold">
                      <input
                        type="text"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="Address"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-3 text-xl font-nunito-sans font-semibold">
                      <input
                        type="text"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="City"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-3 text-xl font-nunito-sans font-semibold">
                      <input
                        type="text"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="Postal Code"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                    <div className="border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 w-full mt-3 text-xl font-nunito-sans font-semibold">
                      <input
                        type="text"
                        className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                        placeholder="Country"
                        //   value={selectedFrequency}
                        //   onChange={(e) => setSelectedFrequency(e?.target?.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              {displayFrame === 1 && (
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col w-full mt-5">
                    <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
                      Payment Method:
                      <span>
                        {/* <img src={InformationLineIcon} alt="img" /> */}
                      </span>
                    </label>
                    <div
                    // className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-3 px-6 gap-2 w-full mt-2"
                    >
                      <select
                        id="countries"
                        className="text-[#616161] outline-none border border-[#CCCCCC] bg-[#FBFBFB] flex justify-center items-center py-1 px-6 gap-2 mt-2  text-xl font-nunito-sans font-semibold w-full"
                        //   onChange={(e) =>
                        //     setSelectedFrequencyType(e?.target?.value)
                        //   }
                        //   value={selectedFrequencyType}
                      >
                        {/* <option selected>Select frequency</option> */}
                        <option selected value="3inch x 4.5inch">
                          Cash on Delivery
                        </option>
                        <option value="hour">3inch x 4.5inch</option>
                        <option value="day">3inch x 4.5inch</option>
                        <option value="week">3inch x 4.5inch</option>
                        <option value="month">3inch x 4.5inch</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {displayFrame === 0 && (
            <div className="flex justify-center items-center flex-col mt-5">
              <Button
                name={"Process to Pay"}
                className={
                  "font-nunito-sans font-extrabold text-xl text-[#000]"
                }
                //   onClick={handleGeneratePDF}
              />
            </div>
          )}
          {displayFrame === 1 && (
            <div className="flex justify-center items-center flex-col mt-10">
              <Button
                name={"Place Order"}
                className={
                  "font-nunito-sans font-extrabold text-xl text-[#000]"
                }
                //   onClick={handleGeneratePDF}
              />
            </div>
          )}
          {displayFrame === 2 && (
            <div className="flex justify-center items-center flex-col mt-10">
              <Button
                name={"Continue"}
                className={
                  "font-nunito-sans font-extrabold text-xl text-[#000]"
                }
                //   onClick={handleGeneratePDF}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderFrameDrawer;
