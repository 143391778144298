import React, { useState } from "react";
import OrderFrameDrawer from "../../components/orderFrameDrawer";
import OrderFrameBlack from "../../assets/orderFrameBlack.svg";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import leftArrowIcon from "../../assets/leftArrowIcon.svg";

const Orderpage = () => {
  const [displayFrame, setDisplayFrame] = useState(0);
  return (
    <>
      <div className="lg:w-[60%] md:w-[50%] w-full px-5 lg:mx-10 flex justify-center items-center py-10">
        <div className=" flex flex-col justify-center items-center gap-10 w-full h-full">
          <div className="flex w-full justify-between items-center my-5">
            <div className="font-nunito-sans font-bold text-xl text-[#111827] flex gap-2 items-center cursor-pointer">
              <span>
                <img src={leftArrowIcon} alt="icon" />
              </span>
              Back
            </div>
            <div className="flex gap-3 items-center justify-center lg:mr-[30%]">
              <img
                className="h-[21px] w-[21px]"
                loading="eager"
                alt=""
                src={HeaderIcon}
                //   onClick={() => handleRout("/")}
              />
              <span className="uppercase font-bold font-nunito-sans text-[24px] leading-[21px]">
                Living Image
              </span>
            </div>
          </div>
          <div className="w-[95%] ">
            <img src={OrderFrameBlack} alt="img" className="w-full h-full" />
          </div>
          <div>
            <button className="font-nunito-sans font-extrabold text-xl text-[#000] border-2 border-[#000000] px-10 py-1 rounded-full">
              Preview Product
            </button>
          </div>
        </div>
        <div>
          <OrderFrameDrawer isOpen={false} displayFrame={displayFrame} />
        </div>
      </div>
    </>
  );
};

export default Orderpage;
