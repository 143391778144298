import React, { useState } from "react";
import HowCreditWork from "./components/howCreditWork";
import Faq from "../home/components/faq";
import Header from "../../components/header";
import Footer from "../../components/footer";
import MultiDotPricing from "../../assets/multiDotPricing.svg";
import PlanCards from "../../components/plans";

const PricingPage = () => {
  return (
    <>
      <Header />
      <div className="w-full flex flex-col justify-center items-center">
        <div
          className="w-full flex flex-col justify-center items-center"
          style={{
            background: "linear-gradient(to right top, white 50%, #b7f0f2)",
          }}
        >
          <div className="relative flex flex-col justify-center items-center gap-4 md:w-[65%] w-[90%] text-center  mt-20">
            <div className="absolute top-[-40%] hidden md:flex">
              <img src={MultiDotPricing} alt="img" />
            </div>
            <div
              className="font-nunito-sans bg-gradient-to-r from-orange-500 via-red-500 to-pink-500 font-extrabold lg:text-5xl text-3xl sm:text-3xl md:text-3xl text-[#fff] text-center"
              style={{
                // background:
                //   "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Find a plan to power your memories
            </div>
            <div className="font-normal font-nunito-sans lg:text-xl  text-lg sm:text-lg md:text-lg text-[#616161]  text-centers">
              Experience the essence of every moment with our live
              location-based weather updates. Keep your memories fresh and
              vibrant as we seamlessly adapt your photos to the current weather,
              ensuring that every glance brings you back to the place you love.
            </div>
          </div>

          <div className="w-full justify-center items-center flex flex-col mt-10">
            <PlanCards fromPricing={true}/>
          </div>
        </div>
        <div className="w-full justify-center items-center flex mb-20">
          <HowCreditWork />
        </div>
        <div className="mb-20 mt-36 w-full" id="FaqPricing">
          <Faq />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PricingPage;
