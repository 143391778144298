import React, { useEffect, useState } from "react";
import Button from "../../common/button";
import HeaderIcon from "./../../assets/Heaader/HeaderIcon.svg";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { addedEmail } from "../../redux/authstore/authSlice";
import { logout } from "../../Utils/Logout";
import AuthRightCard from "../../components/AuthRightCard";
import queryString from "query-string";
import crossIcon from "./../../assets/cross-icon.png";

const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { upload, prompt, promptText } = queryString.parse(location.search);

  const [otp, setOtp] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isResendLoader, setIsResendLoader] = useState(false);
  const [resendSuccessMsg, setResendSuccessMsg] = useState("");
  const [resendErrMsg, setResendErrMsg] = useState("");
  const [timer, setTimer] = useState(0);

  const email = useSelector(addedEmail);
  const emailAddress = localStorage.getItem("EmailAddress");

  const otpVerification = async (data) => {
    setIsLoader(true);
    const url = "https://api.livingimage.io/api/verification/";
    const header = {
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(url, data, { headers: header });
      console.log("RESPONSE RECEIVED: ", res, res?.status);
      if (res?.status === 200) {
        setOtp("");
        localStorage.setItem("Verified", true);
        localStorage.setItem("AccessToken", res?.data?.token?.access);
        localStorage.setItem(
          "SubscriptionStatus",
          res?.data?.subscription_status
        );
        localStorage.setItem("Membership", res?.data?.membership);
        localStorage.setItem(
          "MembershipExpiry",
          res?.data?.membership_expiry_date
        );
        localStorage.setItem(
          "StripeCustomerId",
          res?.data?.stripe_customer_id
        );
        localStorage.setItem("MembershipId", res?.data?.membership_id);

        setIsLoader(false);
        navigate(
          `/dashboard?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(
            promptText
          )}`
        );
      }
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
      setIsLoader(false);
      setOtp("");
      setErrorMsg(err?.response?.data?.Message);
      if (err?.response?.status === 401) {
        logout();
      }
    }
  };

  const handleOtp = () => {
    if (otp === "") {
      setOtpErrorMsg("Please enter OTP");
    } else {
      setOtpErrorMsg("");
      const data = {
        email: emailAddress,
        verification_code: otp,
      };
      otpVerification(data);
    }
  };

  const handleResendOtp = async () => {
    setIsResendLoader(true);
    const url = "https://api.livingimage.io/api/resendotp/";
    const header = {
      "Content-Type": "application/json",
    };
    const data = {
      email: emailAddress,
    };
    try {
      const res = await axios.post(url, data, { headers: header });
      console.log("RESPONSE RECEIVED: ", res, res?.status);
      if (res?.status === 200) {
        setOtp("");
        setIsResendLoader(false);
        setResendSuccessMsg(res?.data?.Message);
        setTimer(30); // Start the timer after successful OTP resend
      }
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
      setIsResendLoader(false);
      setOtp("");
      setResendErrMsg(err?.response?.data?.Message);
      if (err?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResendSuccessMsg("");
      setResendErrMsg("");
      setErrorMsg("");
    }, 3000);
    return () => clearTimeout(timeout);
  }, [resendSuccessMsg, errorMsg, resendErrMsg]);

  useEffect(() => {
    if (otp === "") {
      setOtp("");
    }
  }, [otp]);

  const verified = JSON.parse(localStorage.getItem("Verified"));

  useEffect(() => {
    if (verified === true) {
      navigate(
        `/dashboard?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(
          promptText
        )}`
      );
    }
  }, [verified, upload, prompt, promptText, navigate]);

  return (
    <div
      className="h-screen w-full flex justify-center items-center"
      style={{
        background: "linear-gradient(to right top, white 40%, #b7f0f2)",
      }}
    >
      <div className="relative min:w-[640px] w-full grid grid-cols-1 sm:grid-cols-2 m-5 md:w-[75%] max my-36 rounded-2xl bg-[#fff] min-h-[80vh]">
        <div
          className="rounded-[50%] bg-[#f84343] text-gray-500 absolute sm:hidden top-6 right-6 py-2 px-2 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={crossIcon} alt="Close" className="mx-1 my-1" />
        </div>

        <div className="flex flex-col items-center justify-center gap-4 py-20 md:mx-20 mx-5">
          <div className="flex gap-2 items-center justify-center w-full">
            <img
              className="lg:w-[45px] lg:h-[45px] w-[45px] h-[45px] sm:w-[45px] sm:h-[45px] md:w-[45px] md:h-[45px]"
              loading="eager"
              alt=""
              src={HeaderIcon}
            />
            <span className="uppercase font-bold flex flex-col font-nunito-sans lg:text-[32px] text-base sm:text-base md:text-base leading-[21px]">
              LivingImage
            </span>
          </div>
          <div
            className="bg-gradient-to-r from-emerald-500 via-cyan-400 to-sky-500 font-nunito-sans font-extrabold text-3xl mt-4"
            style={{ backgroundClip: "text", color: "transparent" }}
          >
            OTP Verification
          </div>

          <div className="flex flex-col gap-4 w-full">
            <input
              type="number"
              placeholder="OTP"
              onChange={(e) => setOtp(e?.target?.value)}
              value={otp}
              name="verification_code"
              className="font-nunito-sans font-normal text-2xl text-[#616161] w-full border border-[#CCCCCC] bg-[#FBFBFB] py-3 px-8 outline-none rounded-lg"
            />
            <div style={{ color: "red", fontSize: "12px" }}>{otpErrorMsg}</div>
          </div>
          <div style={{ color: "red", fontSize: "12px" }}>{errorMsg}</div>
          <div style={{ color: "green", fontSize: "14px" }}>
            {resendSuccessMsg}
          </div>
          <div style={{ color: "red", fontSize: "12px" }}>{resendErrMsg}</div>
          <div className="w-full flex justify-center items-center">
            {isLoader ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.553C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.5908 14.5662 79.6546 9.88283 73.0409 6.82373C66.4272 3.76464 59.3233 2.42939 52.2038 2.93116C48.861 3.1634 46.6953 6.13181 47.3324 8.55724C47.9695 10.9827 50.4397 12.2856 53.5055 12.0927C58.6346 11.7843 63.7924 12.871 68.2748 15.2562C72.7573 17.6415 76.3937 21.2527 78.9043 25.6759C81.0717 29.4464 82.4565 33.6144 83.0032 37.8839C83.4473 41.0545 85.5423 43.3925 87.9676 42.7554Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ) : (
              <Button
                onClick={handleOtp}
                name={"Submit"}
                className={"w-full"}
              />
            )}
          </div>
          <div className="flex justify-center items-center gap-2">
            <div className="font-nunito-sans">Didn’t get OTP?</div>
            <button
              onClick={handleResendOtp}
              className="text-[#0052F6] font-nunito-sans"
              disabled={timer > 0 ? true : false}
            >
              Resend OTP {timer > 0 && `in ${timer}s`}
            </button>
          </div>

          <div className="text-center visible sm:hidden">
            <div className="font-nunito-sans text-2xl">
              Already have an account?
            </div>
            <a
              href={`/login?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(
                promptText
              )}`}
              className="text-2xl underline font-nunito-sans"
            >
              Sign In
            </a>
          </div>
        </div>

        <AuthRightCard
          heading="Hello, User!"
          paragraph="Please enter the OTP sent to your email address to verify your account and start generating images with AI."
          subtext="Already have an account?"
          buttonTxt="Sign in"
          buttonUrl={`/login?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(
            promptText
          )}`}
        />
      </div>
    </div>
  );
};

export default OtpVerification;
