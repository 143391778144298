import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { logout } from "../Utils/Logout";
import redirectingImage from "./../assets/redirecting-to-checkout.gif";

const Checkout = () => {
  const [dots, setDots] = useState('');
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(5);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const membershipId = searchParams.get("id");
  const creditsToBuy = parseInt(searchParams.get("credits"));

  const accessToken = localStorage.getItem("AccessToken");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const payload = type === "buy" ? {
    total_credits: creditsToBuy
  } : {
    membership_id: membershipId,
  }

  const getStripeSession = async () => {
    try {
      const response = await axios.post(
        "https://api.livingimage.io/checkout/",
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        redirectToCheckout(response?.data?.sessionId || response?.data?.["Session ID"]?.id);
      } else setError("An error occurred");
    } catch (error) {
      setError(error?.message);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    if (!accessToken) {
      setError("You are not logged in. Please login first.");
    }
    if ((type === "membership" && membershipId && error === '') || (type === "buy" && creditsToBuy && creditsToBuy !== 0 && error === '')) {
      getStripeSession();
    } else {
      setError("Invalid type or membership id.");
    }
  }, []);

  const redirectToCheckout = async (sessionId) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });
    if (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const dotSequence = ['.', '..', '...'];
    let index = 0;

    const intervalId = setInterval(() => {
      setDots(dotSequence[index]);
      index = (index + 1) % dotSequence.length;
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (error !== '') {
      const countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval);
            window.location.href = "/dashboard";
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [error]);

  return (
    <div className="flex items-center justify-around gap-5 px-20 h-screen">
      <div>
        <p className="text-8xl font-nunito-sans font-bold">{error === "" ? "Hang on," : "Oh no"}</p>
        <p className="text-3xl font-nunito-sans">
          {error === ""
            ? `You are being redirected to checkout page${dots}`
            : <>
                An error occurred<br />{error}
                <br />
                <span className="text-xl">Redirecting to <a href="/dashboard" className="underline text-blue-500">dashboard</a> in {countdown}s.</span>
              </>
          }
        </p>
      </div>
      <img src={redirectingImage} alt="Redirecting.." className="hidden sm:block"/>
    </div>
  );
};

export default Checkout;
