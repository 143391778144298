import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "authData",
  initialState: {
    email: "ceibouppussine-3852@yopmail.com",
  },
  reducers: {
    addEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { addEmail } = authSlice.actions;

export const addedEmail = (state) => state.authData.email;

export default authSlice.reducer;
