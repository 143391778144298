import React from "react";
import DashboardHeader from "../../components/dashboardHeader";
import PaymentList from "./components/paymentList";

const PaymentPage = () => {
  return (
    <div className="w-full flex bg-[#FBFBFB] h-screen overflow-auto">
      <div className="w-full flex flex-col items-center gap-5 my-5">
        <div className="w-[90%]">
          <DashboardHeader />
        </div>
        <div className="w-[90%] h-screen">
          <PaymentList />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
