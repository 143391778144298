import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/dashboardHeader";
import { useNavigate } from "react-router-dom";
import { FaTimesCircle, FaCreditCard, FaSyncAlt } from "react-icons/fa";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { logout } from "../Utils/Logout";
import Modal from "react-modal";

const Subscription = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPlan, setCurrentPlan] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noMembership, setNoMembership] = useState(true);
  const [showUpgradeButton, setShowUpgradeButton] = useState(false);

  const membershipId = localStorage.getItem("MembershipId");
  const membershipExpiry = localStorage.getItem("MembershipExpiry");
  const accessToken = localStorage.getItem("AccessToken");

  const getPlans = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.livingimage.io/api/get_membership/",
        null
      );
      setLoading(false);

      if (response?.status === 200) {
        setPlans(response.data?.Membership_details);

        let found = false;

        for (const item of response.data?.Membership_details) {
          if (
            item?.["Membership ID"] === parseInt(membershipId) ||
            item?.["Membership ID"] === membershipId
          ) {
            setCurrentPlan(item);
            setNoMembership(false);
            found = true;
            break;
          }
        }

        if (!found) {
          setErrorMessage("You are not having any valid membership plan.");
          setShowUpgradeButton(true);
        }
      }
    } catch (error) {
      setErrorMessage(error.message || "An error occurred");
      setLoading(false);
    }
  };

  const cancelMembership = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.livingimage.io/api/cancel_membership/",
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      if (response?.status === 200) {
        setSuccessMessage("Membership cancelled successfully.");
        localStorage.removeItem("MembershipExpiry");
        localStorage.removeItem("Membership");
        localStorage.removeItem("SubscriptionStatus");
        localStorage.removeItem("MembershipId");
        setNoMembership(true);
      } else {
        setErrorMessage("Failed to cancel membership.");
      }
    } catch (error) {
      setErrorMessage(error.message || "An error occurred");
      setLoading(false);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  const updatePaymentDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.livingimage.io/api/change_card_detail/",
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        if (response?.data?.url) {
          window.location.href = response?.data?.url;
        } else {
          setErrorMessage(
            "An error occurred. Try again or contact us if the issue persists."
          );
          setLoading(false);
        }
      } else {
        setErrorMessage("Failed to update payment details.");
      }
    } catch (error) {
      setErrorMessage(
        error.message ||
          "An error occurred. Try again or contact us if the issue persists."
      );
      setLoading(false);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleCancelSubscription = () => {
    setIsModalOpen(true);
  };

  const handleConfirmCancel = () => {
    cancelMembership();
    setIsModalOpen(false);
  };

  const handleUpdatePaymentDetails = () => {
    updatePaymentDetails();
  };

  const handleUpgradeDowngrade = () => {
    navigate("/upgrade");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full flex h-screen overflow-auto">
      <div className="w-full flex flex-col items-center gap-5 my-5">
        <div className="w-[90%]">
          <DashboardHeader />
        </div>
        <div className="w-[90%] h-screen">
          <div className="w-full border border-gray-300 rounded-2xl bg-white shadow-lg flex px-10 py-8 flex-col gap-7 overflow-auto mb-10">
            <h1 className="font-nunito-sans text-xl text-gray-900">
              Manage Your Subscription
            </h1>
            {loading ? (
              <div className="w-full h-full flex items-center">
                <p className="text-xl text-gray-700">Loading...</p>
              </div>
            ) : (
              <div>
                {errorMessage && (
                  <div>
                    <div
                      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Error: </strong>
                      <span className="block sm:inline">
                        {errorMessage}
                      </span>{" "}
                      <a
                        href="/upgrade"
                        className="text-blue-500 underline "
                        style={{
                          visibility: showUpgradeButton ? "visible" : "hidden",
                        }}
                      >
                        Upgrade Now
                      </a>
                    </div>
                  </div>
                )}
                {successMessage && (
                  <div
                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <strong className="font-bold">Success: </strong>
                    <span className="block sm:inline">{successMessage}</span>
                  </div>
                )}
                {noMembership ? (
                  ""
                ) : (
                  <div>
                    <div className="bg-gray-50 p-5 rounded-lg mb-5 shadow-inner">
                      <h2 className="font-bold text-lg mb-4">Current Plan</h2>
                      <p className="text-sm text-gray-800 mb-2">
                        <span className="font-semibold">Plan Name:</span>{" "}
                        {currentPlan?.["Membership Name"]}
                      </p>
                      <p className="text-sm text-gray-800 mb-2">
                        <span className="font-semibold">Price:</span> $
                        {currentPlan?.["Membership Price"]}/
                        {currentPlan?.["Membership Duration"] === 30
                          ? "month"
                          : "year"}
                      </p>
                      <p className="text-sm text-gray-800 mb-4">
                        <span className="font-semibold">Renewal Date:</span>{" "}
                        {loading ||
                        !membershipExpiry ||
                        membershipExpiry === "null"
                          ? ""
                          : format(parseISO(membershipExpiry), "MMMM dd, yyyy")}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex items-start p-3 bg-white rounded shadow-sm">
                          <svg
                            className="w-6 h-6 text-green-500 flex-shrink-0 mr-3"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <p className="text-sm text-gray-700">
                            Get {currentPlan?.["Membership Credits"]} credits
                            every{" "}
                            {currentPlan?.["Membership Duration"] === 30
                              ? "month"
                              : "year"}
                            .
                          </p>
                        </div>
                        <div className="flex items-start p-3 bg-white rounded shadow-sm">
                          <svg
                            className="w-6 h-6 text-green-500 flex-shrink-0 mr-3"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <p className="text-sm text-gray-700">
                            {currentPlan?.["Membership Feature 1"]}
                          </p>
                        </div>
                        <div className="flex items-start p-3 bg-white rounded shadow-sm">
                          <svg
                            className="w-6 h-6 text-green-500 flex-shrink-0 mr-3"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <p className="text-sm text-gray-700">
                            {currentPlan?.["Membership Feature 2"]}
                          </p>
                        </div>
                        <div className="flex items-start p-3 bg-white rounded shadow-sm">
                          <svg
                            className="w-6 h-6 text-green-500 flex-shrink-0 mr-3"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <p className="text-sm text-gray-700">
                            {currentPlan?.["Membership Feature 3"]}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-8 mt-4">
                      <div className="md:w-1/2">
                        <div className="mb-6 flex items-center">
                          <FaTimesCircle className="text-red-500 mr-3 w-6 h-6" />
                          <p className="text-gray-700">
                            Not finding the plan useful? You can cancel your
                            subscription anytime.
                          </p>
                        </div>
                        <div className="mb-6 flex items-center">
                          <FaCreditCard className="text-blue-500 mr-3 w-6 h-6" />
                          <p className="text-gray-700">
                            Keep your payment information up to date to avoid
                            any interruption in service.
                          </p>
                        </div>
                        <div className="mb-6 flex items-center">
                          <FaSyncAlt className="text-green-500 mr-3 w-6 h-6" />
                          <p className="text-gray-700">
                            Need more features or want to save? You can upgrade
                            or downgrade your plan.
                          </p>
                        </div>
                      </div>
                      <div className="md:w-1/2 flex flex-col gap-4">
                        <button
                          onClick={handleCancelSubscription}
                          className="bg-gray-200 text-gray-700 px-5 py-3 rounded-full shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition ease-in-out duration-300 w-full"
                        >
                          <FaTimesCircle className="inline-block mr-2" /> Cancel
                          Subscription
                        </button>
                        <button
                          onClick={handleUpdatePaymentDetails}
                          className="bg-gray-200 text-gray-700 px-5 py-3 rounded-full shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition ease-in-out duration-300 w-full"
                        >
                          <FaCreditCard className="inline-block mr-2" /> Update
                          Payment Details
                        </button>
                        <button
                          onClick={handleUpgradeDowngrade}
                          className="bg-gray-200 text-gray-700 px-5 py-3 rounded-full shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition ease-in-out duration-300 w-full"
                        >
                          <FaSyncAlt className="inline-block mr-2" />{" "}
                          Upgrade/Downgrade Subscription
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Cancel Subscription Confirmation"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg p-8 w-[90%] max-w-lg">
          <h2 className="text-2xl font-bold mb-4">Confirm Cancellation</h2>
          <p className="mb-6">
            Are you sure you want to cancel your subscription? This action
            cannot be undone.
          </p>
          <div className="flex justify-end gap-4">
            <button
              onClick={handleConfirmCancel}
              className="bg-red-500 text-white px-4 py-2 rounded-full shadow-md hover:bg-red-600 transition ease-in-out duration-300"
            >
              Yes, Cancel
            </button>
            <button
              onClick={closeModal}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-full shadow-md hover:bg-gray-300 transition ease-in-out duration-300"
            >
              No, Go Back
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Subscription;
