import { createSlice } from "@reduxjs/toolkit";

export const dashbordSlice = createSlice({
  name: "dashbordData",
  initialState: {
    isReloadDashbordImgLibrary: false,
    isReloadImgLibraryTab: false,
    creditBalance: 0,
    isUpdateSidebarValue: false,
    isProfileImage: false,
  },
  reducers: {
    updateDeashbordImgLibraryState: (state, action) => {
      state.isReloadDashbordImgLibrary = action.payload;
    },
    updateImgLibraryTabState: (state, action) => {
      state.isReloadImgLibraryTab = action.payload;
    },
    getCreditBalance: (state, action) => {
      state.creditBalance = action.payload;
    },
    getProfileImageState: (state, action) => {
      state.isProfileImage = action.payload;
    },
    updateSidebarValue: (state, action) => {
      state.isUpdateSidebarValue = action.payload;
    },
  },
});

export const {
  updateDeashbordImgLibraryState,
  updateImgLibraryTabState,
  getCreditBalance,
  updateSidebarValue,
  getProfileImageState,
} = dashbordSlice.actions;

export const isReloadDeashbordData = (state) =>
  state?.dashbordData?.isReloadDashbordImgLibrary;

export const isReloadImgLibraryData = (state) =>
  state?.dashbordData?.isReloadImgLibraryTab;

export const isUpdateSidebarValueData = (state) =>
  state?.dashbordData?.isUpdateSidebarValue;

export const isProfileImageData = (state) =>
  state?.dashbordData?.isProfileImage;

export const creditBalanceData = (state) => state?.dashbordData?.creditBalance;

export default dashbordSlice.reducer;
