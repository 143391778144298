import React from "react";
import DashboardHeader from "../../components/dashboardHeader";
import RightIcon from "../../assets/rightIcon.svg";
import Button from "../../common/button";
import { useState } from "react";
import PlanCards from "../../components/plans";

const Upgrade = () => {
    const [planType, setPlanType] = useState(["Monthly", "Annual (Save 25%)"]);
    const [selectedPlanType, setSelectedPlanType] = useState(0);



    return (
        <div className="w-full flex bg-[#FBFBFB] h-screen overflow-auto">
            <div className="w-full flex flex-col items-center gap-5 my-5">
                <div className="w-[90%]">
                    <DashboardHeader />
                </div>
                <div className="w-[90%] border border-[#CCCCCC] rounded-[20px] bg-[#fff] flex justify-center px-10 py-8 flex-col gap-7">
                    <PlanCards upgrade = {true}/>
                </div>
            </div>
        </div>
    );
};

export default Upgrade;