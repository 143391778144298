import React, { useEffect, useState } from "react";
import Image01 from "../../../assets/image01.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { isReloadImgLibraryData } from "../../../redux/dashbordSlice/dashbordSlice";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Utils/Logout";

const GeneratedLibrary = () => {
  const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const [generatedLibraryTab, setGeneratedLibrary] = useState([
    "Generated Library",
    "Original Library",
  ]);
  const [selectedTeb, setSelectedTeb] = useState(0);
  const [imageLibraryError, setImageLibraryError] = useState("");

  const accessToken = localStorage.getItem("AccessToken");
  const [page, setPage] = useState(1);
  const [isLoder, setIsLoder] = useState(false);
  const isReloadData = useSelector(isReloadImgLibraryData);

  const [isDeleteSelect, setIsDeleteSelect] = useState(false);
  const [selectedIcemForDelete, setSelectedIcemForDelete] = useState([]);

  const [selectedIcemForDeleteId, setSelectedIcemForDeleteId] = useState([]);
  const [isDeleteBtnLoder, setIsDeleteBtnLoder] = useState(false);

  const getAllImage = async () => {
    setIsLoder(true);
    if (selectedTeb === 0) {
      try {
        const response = await axios.get(
          `https://api.livingimage.io/api/get-public-regen-image/?page=${page}`
        );
        if (response?.status === 200) {
          setImages(response?.data?.results?.Regenerated_Image_data);
          console.log(response.data, response);
          setIsLoder(false);
        }
      } catch (error) {
        console.log("Axios Error: ", error);
        setImageLibraryError(error?.response?.data?.errors?.detail);
        setIsLoder(false);
        if (error?.response?.status === 401) {
          logout()
        }
      }
    } else if (selectedTeb === 1) {
      try {
        const response = await axios.get(
          `https://api.livingimage.io/api/get-public-original-image/?page=${page}`
        );
        if (response?.status === 200) {
          setImages(response?.data?.results?.Original_Image_data);
          console.log(response.data, response);
          setIsLoder(false);
        }
      } catch (error) {
        console.log("Axios Error: ", error);
        setImageLibraryError(error?.response?.data?.errors?.detail);
        setIsLoder(false);
        if (error?.response?.status === 401) {
          logout()
        }
      }
    }
  };

  const handleDeleteChange = (e) => {
    setIsDeleteSelect((prevValue) => (prevValue === false ? true : false));
  };

  const handleSingleImage = (imgData, imgIndex) => {
    if (isDeleteSelect) {
      let tempIndexArray = [...selectedIcemForDelete];
      let tempIndexArrayId = [...selectedIcemForDeleteId];
      if (selectedIcemForDelete.includes(imgIndex)) {
        tempIndexArray.filter((item, index) => {
          if (item === imgIndex) {
            tempIndexArray.splice(index, 1);
          }
        });
        setSelectedIcemForDelete(tempIndexArray);
        tempIndexArrayId.filter((item, index) => {
          if (selectedTeb === 0) {
            if (item === imgData?.regenerated_image_id) {
              tempIndexArrayId.splice(index, 1);
            }
          } else if (selectedTeb === 1) {
            if (item === imgData?.original_image_id) {
              tempIndexArrayId.splice(index, 1);
            }
          }
        });
        setSelectedIcemForDeleteId(tempIndexArrayId);
      } else {
        tempIndexArray.push(imgIndex);
        if (selectedTeb === 0) {
          tempIndexArrayId.push(imgData.regenerated_image_id);
        } else if (selectedTeb === 1) {
          tempIndexArrayId.push(imgData.original_image_id);
        }
        setSelectedIcemForDelete(tempIndexArray);
        setSelectedIcemForDeleteId(tempIndexArrayId);
      }
    } else {
      localStorage.setItem(
        "imageIdOrignandRegen",
        JSON.stringify({
          original_image_id: imgData?.original_image_id,
          regenerated_image_id: imgData?.regenerated_image_id,
        })
      );
      navigate("/generateimageoutput");
    }
  };

  const handleDeleteImg = async () => {
    if (isDeleteSelect) {
      const deleteImageID = {
        image_id: selectedIcemForDeleteId,
      };

      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      console.log("deleteImageID -- ", deleteImageID);
      if (deleteImageID?.length !== 0) {
        setIsDeleteBtnLoder(true);
        try {
          const response = await axios.post(
            `https://api.livingimage.io/api/delete/`,
            deleteImageID,
            axiosConfig
          );
          if (response?.status === 200) {
            // setImages(response?.data?.results?.Regenerated_Image_data);
            console.log(response.data, response);
            setIsDeleteBtnLoder(false);
          }
        } catch (error) {
          console.log("Axios Error: ", error);
          // setImageLibraryError(error?.response?.data?.errors?.detail);
          setIsDeleteBtnLoder(false);
          if (error?.response?.status === 401) {
            logout()
          }
        }
      }
    }
  };

  useEffect(() => {
    getAllImage();
  }, [selectedTeb]);

  useEffect(() => {
    console.log("isReload image iliiii=== ", isReloadData);
    if (isReloadData) {
      getAllImage();
    }
  }, [isReloadData]);

  useEffect(() => {
    setTimeout(() => {
      setImageLibraryError("");
    }, 3000);
  }, [imageLibraryError]);

  console.log("images ===", images);

  return (
    <>
      <div className="w-full border border-[#CCCCCC] rounded-[20px] bg-[#fff] flex px-10 py-8 flex-col gap-4 h-screen overflow-auto mb-10 ">
        <div className="border-b border-b-[#CCCCCC] flex justify-between items-center w-full">
          {/* <div
            className="lg:text-[22px] text-lg sm:text-lg md:text-lg font-nunito-sans font-bold text-[#000]"
            style={{
              border:
                "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
            }}
          >
            Generated Library
          </div> */}
          <div className="flex md:gap-x-20 gap-x-5 h-full">
            {generatedLibraryTab?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    selectedTeb === index
                      ? "lg:border-b-2 lg:border-transparent"
                      : "lg:border-b-0 lg:border-transparent"
                  } flex items-center lg:py-5  sm:border-0 md:border-0 lg:text-[22px] text-lg sm:text-lg md:text-lg font-nunito-sans font-bold text-[#000]  cursor-pointer`}
                  style={{
                    borderImage:
                      "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                    borderImageSlice: "1",
                  }}
                  onClick={() => setSelectedTeb(index)}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-10">
            {isDeleteSelect && (
              <div className="flex justify-center items-center text-base font-nunito-sans font-semibold text-[#000]">
                {selectedIcemForDelete?.length} Selected photos
              </div>
            )}
            {isDeleteBtnLoder ? (
              <div
                role="status"
                className="flex justify-center items-center w-full"
              >
                <svg
                  aria-hidden="true"
                  class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <div
                className="flex justify-center items-center gap-2 cursor-pointer"
                onClick={handleDeleteImg}
              >
                <img src={DeleteIcon} alt="img" />
                <span className="text-base font-nunito-sans font-semibold text-[#000]">
                  Delete
                </span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <div className="">
                {/* Styled checkbox to look like a radio button */}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isDeleteSelect}
                    onChange={handleDeleteChange}
                    className="hidden" // Hide the actual checkbox
                  />

                  {/* Custom radio button style */}

                  <span
                    className={`relative flex w-4 h-4 rounded-full border justify-center items-center
                                ${
                                  isDeleteSelect
                                    ? "border-blue-500 bg-white"
                                    : "border-gray-400 bg-white"
                                }
                                transition duration-200 ease-in-out
                                hover:border-blue-500 focus:border-blue-500
                                hover:ring-2 hover:ring-blue-200 focus:ring-2 focus:ring-blue-200
                    `}
                  >
                    {/* Inner dot to represent the radio button's checked state */}
                    {isDeleteSelect && (
                      <span className="absolute w-[10px] h-[10px] bg-blue-500 rounded-full"></span>
                    )}
                  </span>
                </label>
              </div>
              <label
                for="default-radio-1"
                className="text-base font-nunito-sans font-semibold text-[#000]"
              >
                Select
              </label>
            </div>
          </div>
        </div>
        {/* <div> */}
        <div className="flex flex-wrap gap-x-6 gap-y-6 items-start justify-start h-screen overflow-scroll ">
          {isLoder ? (
            <div
              role="status"
              className="flex justify-center items-center w-full"
            >
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : images?.length !== 0 ? (
            images?.map((item, index) => {
              return (
                <div
                  className="flex flex-col gap-2 h-fit cursor-pointer relative"
                  key={index}
                  onClick={() => handleSingleImage(item, index)}
                >
                  {isDeleteSelect && (
                    <div className="flex items-center gap-2 p-2 absolute">
                      <label className="inline-flex items-center cursor-pointer">
                        {/* <input
                        type="checkbox"
                        checked={
                          selectedIcemForDelete.includes(index) ? true : false
                        }
                        // onChange={handleDeleteChange}
                        className="hidden"
                      /> */}
                        <span
                          className={`relative flex w-4 h-4 rounded-full border justify-center items-center
                              ${
                                selectedIcemForDelete.includes(index)
                                  ? "border-blue-500 bg-white"
                                  : "border-gray-400 bg-white"
                              }
                              transition duration-200 ease-in-out
                              hover:border-blue-500 focus:border-blue-500
                              hover:ring-2 hover:ring-blue-200 focus:ring-2 focus:ring-blue-200
                  `}
                        >
                          {selectedIcemForDelete.includes(index) && (
                            <span className="absolute w-[10px] h-[10px] bg-blue-500 rounded-full"></span>
                          )}
                        </span>
                      </label>
                    </div>
                  )}
                  <img
                    src={`${
                      selectedTeb === 0
                        ? item?.regenerated_image
                        : item?.original_image
                    }`}
                    alt="img"
                    className="md:w-[171px] md:h-[179px] h-[109px] w-[104px] sm:h-[109px] sm:w-[104px] rounded-lg"
                  />
                  <span className="font-nunito-sans font-semibold text-lg text-[#616161]">
                    {item?.name ? item?.name : "Paris 20234"}
                  </span>
                </div>
                // <div className="flex flex-col gap-2 h-fit" key={index}>
                //   <img
                //     src={`${
                //       selectedTeb === 0
                //         ? item?.regenerated_image
                //         : item?.original_image
                //     }`}
                //     alt="img"
                //     className="md:w-[171px] md:h-[179px] h-[109px] w-[104px] sm:h-[109px] sm:w-[104px] rounded-lg"
                //   />
                //   <span className="font-nunito-sans font-semibold text-lg text-[#616161]">
                //     {item?.name ? item?.name : "Paris 20234"}
                //   </span>
                // </div>
              );
            })
          ) : (
            <div className="font-nunito-sans font-semibold text-lg text-[#616161] text-center w-full">
              Images not found
            </div>
          )}
          {imageLibraryError && (
            <div className="font-nunito-sans font-semibold text-lg text-red-700 w-full text-center">
              {imageLibraryError}
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default GeneratedLibrary;
