import React from "react";
import successImg from "./../assets/payment-success-img.gif";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { logout } from "../Utils/Logout";
import axios from "axios";

const PaymentSuccess = () => {
  const [dots, setDots] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const accessToken = localStorage.getItem("AccessToken");

  useEffect(() => {
    const dotSequence = [".", "..", "..."];
    let index = 0;

    const intervalId = setInterval(() => {
      setDots(dotSequence[index]);
      index = (index + 1) % dotSequence.length;
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const getPaymentDetails = async () => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.post(
          "https://api.livingimage.io/api/get_latest_payment/",
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response?.status === 200) {
          // SET PAYMENT DETAILS TO LOCALSTORAGE FROM HERE
          localStorage.setItem("MembershipId", response?.data?.Payment_details?.membership);
          localStorage.setItem(
            "SubscriptionStatus",
            response?.data?.Payment_details?.subscription_status
          );
          localStorage.setItem("Membership", response?.data?.Payment_details?.membership_name);
          localStorage.setItem(
            "MembershipExpiry",
            response?.data?.Payment_details?.membership_expiry
          );
          localStorage.setItem(
            "StripeCustomerId",
            response?.data?.Payment_details?.stripe_customer_id
          );
        }
      } catch (error) {
        setError(error?.message);
        if (error?.response?.status === 401) {
          logout();
        }
      }
    };

    await Promise.all([fetchPaymentDetails(), new Promise(resolve => setTimeout(resolve, 5000))]);
    navigate("/dashboard");
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <div className="flex items-center justify-around gap-5 px-20 h-screen">
      <div>
        <p className="text-7xl font-nunito-sans font-bold">Success!</p>
        <p className="text-xl font-nunito-sans mt-1">
          Payment success. Show your creativity by writing prompts and by
          uploading artistic images.
        </p>
        <p className="font-nunito-sans mt-3 text-lg">
          Redirecting to{" "}
          <a
            className="text-blue-500 underline cursor-pointer"
            href="/dashboard"
          >
            dashboard
          </a>
          {dots}
        </p>
      </div>
      <img src={successImg} alt="Payment Success" className="w-[60%] h-[60%] hidden sm:block" />
    </div>
  );
};

export default PaymentSuccess;
