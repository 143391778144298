import React, { useEffect, useState, useRef } from "react";
import EyeIcon from "../../../assets/eyeIcon.svg";
import Button from "../../../common/button";
import ProfileIcon from "../../../assets/profileIcon.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getProfileImageState } from "../../../redux/dashbordSlice/dashbordSlice";
import { logout } from "../../../Utils/Logout";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const initialPassvalue = {
    cPass: "",
    nPass: "",
    nRPass: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [profileData, setProfileData] = useState();
  const [cPassVisible, setCPassVisible] = useState(false);
  const [nPassVisible, setNPassVisible] = useState(false);
  const [nRPassVisible, setNRPassVisible] = useState(false);
  const [profileError, setProfileError] = useState("");
  const [updatePasswordData, setUpdatePasswordData] = useState(initialPassvalue);
  const [cPassError, setCPassError] = useState("");
  const [nPassError, setNPassError] = useState("");
  const [nRPassError, setNRPassError] = useState("");

  const [profileImg, setProfileImg] = useState("");
  const [selectedProfileImg, setSelectedProfileImg] = useState(null);
  const [imgUploadMsg, setImgUploadMsg] = useState("");
  const [imgUploadErrMsg, setImgUploadErrMsg] = useState("");
  const [nameError, setNameError] = useState("");
  const [nameSuccess, setNameSuccess] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");

  const [isLoder, setIsLoder] = useState(false);
  const [isLoderGetProfile, setIsLoderGetProfile] = useState(false);
  const [isLoderNameUpdate, setIsLoderNameUpdate] = useState(false);
  const [isLoderPasswordUpdate, setIsLoderPasswordUpdate] = useState(false);

  const accessToken = localStorage.getItem("AccessToken");

  const handleChange = (e) => {
    setUpdatePasswordData({
      ...updatePasswordData,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const handleNameChange = (e) => {
    setProfileData({
      ...profileData,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const getProfileData = async () => {
    setIsLoderGetProfile(true);
    try {
      const response = await axios.get("https://api.livingimage.io/api/profile/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response?.status === 200) {
        setProfileData(response.data?.personal_data);
      }
    } catch (error) {
      setProfileError(error?.response?.data?.errors?.detail);
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setIsLoderGetProfile(false);
    }
  };

  const handleChangePasswordApi = async (data) => {
    setIsLoderPasswordUpdate(true);
    const passData = { old_password: data?.cPass, new_password: data?.nPass };
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/change-password/",
        passData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        setPasswordSuccess(response?.data?.Message);
        setUpdatePasswordData(initialPassvalue);
      }
    } catch (error) {
      setProfileError(error?.response?.data?.Message || "Error updating password");
      setUpdatePasswordData(initialPassvalue);
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setIsLoderPasswordUpdate(false);
    }
  };

  const handleChangePassword = () => {
    if (
      updatePasswordData?.cPass === "" ||
      updatePasswordData?.nPass === "" ||
      updatePasswordData?.nRPass === "" ||
      updatePasswordData?.nPass !== updatePasswordData?.nRPass
    ) {
      if (updatePasswordData?.cPass === "") {
        setCPassError("Please enter Current Password");
      } else {
        setCPassError("");
      }

      if (updatePasswordData?.nPass === "") {
        setNPassError("Please enter New password");
      } else {
        setNPassError("");
      }

      if (updatePasswordData?.nRPass === "") {
        setNRPassError("Please Re Enter New password");
      } else if (updatePasswordData?.nPass !== updatePasswordData?.nRPass) {
        setNRPassError("New Password and Re Enter password does not match");
      } else {
        setNRPassError("");
      }
    } else if (
      updatePasswordData?.cPass !== "" &&
      updatePasswordData?.nPass !== "" &&
      updatePasswordData?.nRPass &&
      updatePasswordData?.nPass === updatePasswordData?.nRPass
    ) {
      handleChangePasswordApi(updatePasswordData);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setImgUploadErrMsg("");
      return;
    }
    if (file.size > 1048576) {
      setImgUploadErrMsg("The uploaded image exceeds the maximum size of 1 MB.");
      setSelectedProfileImg(null);
    } else {
      setSelectedProfileImg(file);
      setProfileImg("");
      setImgUploadErrMsg("");
    }
  };

  const getProfileImage = async () => {
    setIsLoderGetProfile(true);
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-profile-pic/",
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        setProfileImg(
          response?.data?.Profile_Picture
            ? response?.data?.Profile_Picture + "?" + new Date().getTime()
            : null
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setIsLoderGetProfile(false);
    }
  };

  const uploadProfileImage = async () => {
    if (!selectedProfileImg) {
      fileInputRef.current.click();
      return;
    }
    setIsLoderGetProfile(true);
    const formData = new FormData();
    formData.append("photo", selectedProfileImg);
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/set-profile-pic/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        setProfileImg(response?.data?.S3_URL + "?" + new Date().getTime());
        setImgUploadMsg(response?.data?.Message);
        dispatch(getProfileImageState(true));
      }
    } catch (error) {
      if (error.response.status === 413) {
        setImgUploadErrMsg("The uploaded image is too large. Please upload a smaller image.");
      } else {
        setImgUploadErrMsg(error?.response?.data?.errors?.detail || "An error occurred while uploading the image.");
      }
      setSelectedProfileImg(null);
      getProfileImage();
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setIsLoderGetProfile(false);
    }
  };

  const updateUserName = async () => {
    if (!profileData?.name) {
      setNameError("Name cannot be empty");
      return;
    }
    setIsLoderNameUpdate(true);
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/update-user/",
        { name: profileData?.name },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        setNameSuccess(response.data?.Message);
      }
    } catch (error) {
      setNameError(error?.response?.data?.Message || "Error updating name");
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setIsLoderNameUpdate(false);
    }
  };

  useEffect(() => {
    getProfileData();
    getProfileImage();
  }, []);

  useEffect(() => {
    if (updatePasswordData?.cPass === "") {
      setCPassError("");
    }
    if (updatePasswordData?.nPass === "") {
      setNPassError("");
    }
    if (updatePasswordData?.nRPass === "") {
      setNRPassError("");
    }
  }, [updatePasswordData]);

  useEffect(() => {
    setTimeout(() => {
      setProfileError("");
      setPasswordSuccess("");
      setImgUploadMsg("");
      setImgUploadErrMsg("");
      setNameError("");
      setNameSuccess("");
    }, 3000);
  }, [profileError, passwordSuccess, imgUploadMsg, imgUploadErrMsg, nameError, nameSuccess]);

  return (
    <div className="w-full border border-[#CCCCCC] rounded-[20px] bg-[#fff] flex px-10 py-8 flex-col gap-7 h-screen overflow-auto">
      <div className="w-full">
        <h1 className="font-nunito-sans font-black text-xl lg:text-[28px] text-[#000]">
          Account Settings
        </h1>
        <div className="flex lg:items-center gap-5 flex-col lg:flex-row mt-8">
          <div className="flex justify-center items-center flex-col">
            <div className="flex w-full items-center justify-center bg-grey-lighter">
              <label className="w-full bg-white flex justify-center items-start text-black rounded-[120px] cursor-pointer">
                <span>
                  {isLoderGetProfile ? (
                    <div
                      role="status"
                      className="h-[112px] w-[112px] flex justify-center items-center"
                    >
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <img
                      src={
                        selectedProfileImg !== null
                          ? URL.createObjectURL(selectedProfileImg)
                          : profileImg !== "" && profileImg
                          ? profileImg
                          : ProfileIcon
                      }
                      alt="Profile"
                      className="h-[112px] w-[112px] rounded-full object-fill"
                    />
                  )}
                </span>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="hidden"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                />
              </label>
            </div>
          </div>

          <div className="flex gap-5">
            <button
              className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-6 rounded-full h-fit"
              onClick={uploadProfileImage}
            >
              Upload
            </button>
          </div>
        </div>
        {/* <div className="text-sm text-gray-600 mt-2">
          Profile image of only max 1 MB is allowed.
        </div> */}
        {imgUploadErrMsg && (
          <div className="text-sm text-red-700 w-full text-start mt-2">
            {imgUploadErrMsg}
          </div>
        )}
        {imgUploadMsg && (
          <div className="text-sm text-green-700 w-full text-start mt-2">
            {imgUploadMsg}
          </div>
        )}
        <div className="flex flex-col lg:flex-row w-full gap-20 justify-center items-center">
          <div className="w-full mt-10 flex flex-col gap-5">
          <div className="font-nunito-sans font-extrabold text-xl text-[#1D1D1D]">
              User Details
            </div>
            <div>
              <label className="font-bold text-lg font-nunito-sans text-[#1D1D1D]">
                Email
              </label>
              <div className="border border-[#CCCCCC] rounded-[3px] bg-[#FBFBFB] flex justify-start items-start p-2 gap-2 w-full">
                <input
                  type="text"
                  className="text-[#7D7D7D] font-nunito-sans font-normal text-base outline-none px-1 bg-[#FBFBFB] w-full"
                  placeholder="Aqibawais98@gmail.com"
                  value={profileData?.email}
                  disabled
                />
              </div>
            </div>
            <div>
              <label className="font-bold text-lg font-nunito-sans text-[#1D1D1D]">
                Name
              </label>
              <div className="border border-[#CCCCCC] rounded-[3px] bg-[#FBFBFB] flex justify-start items-start p-2 gap-2 w-full">
                <input
                  type="text"
                  className="text-[#7D7D7D] font-nunito-sans font-normal text-base outline-none px-1 bg-[#FBFBFB] w-full"
                  placeholder="eg. Sajal"
                  value={profileData?.name}
                  name="name"
                  onChange={handleNameChange}
                />
              </div>
              <button
                className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-6 rounded-full h-fit mt-6 flex items-center justify-center"
                onClick={updateUserName}
                disabled={isLoderNameUpdate}
              >
                {isLoderNameUpdate ? (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  "Update Name"
                )}
              </button>
              {nameError && (
                <div className="text-sm text-red-700 w-full text-start mt-2">
                  {nameError}
                </div>
              )}
              {nameSuccess && (
                <div className="text-sm text-green-700 w-full text-start mt-2">
                  {nameSuccess}
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col gap-5">
            <div className="font-nunito-sans font-extrabold text-xl text-[#1D1D1D]">
              Change Password
            </div>
            <div>
              <label className="font-bold text-lg font-nunito-sans text-[#1D1D1D]">
                Current Password
              </label>
              <div className="border border-[#CCCCCC] rounded-[3px] bg-[#FBFBFB] flex justify-start items-start p-2 gap-2 w-full">
                <input
                  type={cPassVisible ? "text" : "password"}
                  className="text-[#7D7D7D] font-nunito-sans font-normal text-base outline-none px-1 bg-[#FBFBFB] w-full"
                  value={updatePasswordData?.cPass}
                  name="cPass"
                  onChange={handleChange}
                />
                <img
                  src={EyeIcon}
                  alt="img"
                  className="pr-3 cursor-pointer"
                  onClick={() => setCPassVisible(!cPassVisible)}
                />
              </div>
              {cPassError && (
                <div className="text-sm text-red-700 w-full text-start">
                  {cPassError}
                </div>
              )}
            </div>
            <div>
              <label className="font-bold text-lg font-nunito-sans text-[#1D1D1D]">
                New Password
              </label>
              <div className="border border-[#CCCCCC] rounded-[3px] bg-[#FBFBFB] flex justify-start items-start p-2 gap-2 w-full">
                <input
                  type={nPassVisible ? "text" : "password"}
                  className="text-[#7D7D7D] font-nunito-sans font-normal text-base outline-none px-1 bg-[#FBFBFB] w-full"
                  value={updatePasswordData?.nPass}
                  name="nPass"
                  onChange={handleChange}
                />
                <img
                  src={EyeIcon}
                  alt="img"
                  className="pr-3 cursor-pointer"
                  onClick={() => setNPassVisible(!nPassVisible)}
                />
              </div>
              {nPassError && (
                <div className="text-sm text-red-700 w-full text-start">
                  {nPassError}
                </div>
              )}
            </div>
            <div>
              <label className="font-bold text-lg font-nunito-sans text-[#1D1D1D]">
                Re Enter New Password
              </label>
              <div className="border border-[#CCCCCC] rounded-[3px] bg-[#FBFBFB] flex justify-start items-start p-2 gap-2 w-full">
                <input
                  type={nRPassVisible ? "text" : "password"}
                  className="text-[#7D7D7D] font-nunito-sans font-normal text-base outline-none px-1 bg-[#FBFBFB] w-full"
                  value={updatePasswordData?.nRPass}
                  name="nRPass"
                  onChange={handleChange}
                />
                <img
                  src={EyeIcon}
                  alt="img"
                  className="pr-3 cursor-pointer"
                  onClick={() => setNRPassVisible(!nRPassVisible)}
                />
              </div>
              {nRPassError && (
                <div className="text-sm text-red-700 w-full text-start">
                  {nRPassError}
                </div>
              )}
            </div>
            <button
              className="font-nunito-sans lg:font-bold font-normal lg:text-lg text-base text-[#fff] bg-[#1D1D1D] py-2 px-6 rounded-full h-fit mt-4 w-full flex items-center justify-center"
              onClick={handleChangePassword}
              disabled={isLoderPasswordUpdate}
            >
              {isLoderPasswordUpdate ? (
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Update Password"
              )}
            </button>
            {profileError && (
              <div className="text-sm text-red-700 w-full text-start mt-2">
                {profileError}
              </div>
            )}
            {passwordSuccess && (
              <div className="text-sm text-green-700 w-full text-start mt-2">
                {passwordSuccess}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
