import React from "react";

const Button = ({ name, className, color, onClick, disabled, border }) => {
  return (
    <button
      className={`${className} "font-extrabold font-nunito-sans text-xl bg-white w-fit text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] "`}
      style={{
        background:
          "linear-gradient(to right, white, white) padding-box padding-box, linear-gradient(270deg, rgb(101 66 26) 100%, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) border-box border-box",
        // borderImageSlice: "1",
        // backgroundClip: "padding-box, border-box",
        // backgroundOrigin: "padding-box, border-box",
        border: border || "3px solid transparent",
        // borderRadius: "120px",
        // padding:"4px 40px"
        color:color
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </button>
  );
};

export default Button;
