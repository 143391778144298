import React from "react";
import DashboardHeader from "../../components/dashboardHeader";
import GeneratedLibrary from "./components/generatedLibrary";

const ImageLibrary = () => {
  return (
    <div>
      <div className="w-full flex bg-[#FBFBFB] h-screen overflow-auto">
        {/* <div className=" min-w-fit h-screen">
          <SideBar />
        </div> */}
        <div className="w-full flex flex-col items-center gap-5 my-5">
          <div className="w-[90%]">
            <DashboardHeader />
          </div>
          <div className="w-[90%] h-screen">
            <GeneratedLibrary />
          </div>
          {/* <div>
              <Button
                name={"Upload image"}
                className="font-extrabold font-nunito-sans text-2xl text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
                style={
                  "background: linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch"
                }
              />
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default ImageLibrary;
