import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import { useDispatch } from "react-redux";
import { updateSidebarValue } from "../../redux/dashbordSlice/dashbordSlice";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname;

  const [isVisible, setIsVisible] = useState(false);

  const handleRout = (rout) => {
    navigate(`${rout}`);
  };

  const authData = localStorage.getItem("AuthData");
  // const verified = localStorage.getItem("Verified");
  const verified = JSON.parse(localStorage.getItem("Verified"));

  const generateReport = () => {
    const divToScroll = document.getElementById(
      pathname === "/pricing" ? "FaqPricing" : "FaqHome"
    );
    if (divToScroll) {
      divToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
    dispatch(updateSidebarValue(true));
  };

  return (
    <>
      <header
        className="w-full bg-white lg:border-b border-b-[#CCC] z-40 opacity-90"
        // style={{
        //   background:
        //     "linear-gradient(102.01deg, #EAF3FD 2.71%, #FAFAFF 12.81%, #D8E7FD 26.77%, #B9E0F8 36.05%, #B0E8F0 47.32%, #C7F7EC 55.33%, #F7ECF4 63.26%, #D7FBEF 70.83%, #D9F2F5 78.13%, #EFF2FF 83.81%, #FFFFFF 94.21%)",
        // }}
        style={{
          background: "linear-gradient(to right, white 30%, #b7f0f2)",
        }}
      >
        <nav className="bg-transparent border-gray-200 px-4 w-full min-w-fit container mx-auto">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <div
              className="flex gap-2 items-center justify-center cursor-pointer"
              onClick={() => handleRout("/")}
            >
              <img
                className="lg:w-[38px] lg:h-[38px]  w-[24px] h-[24px]  sm:w-[24px] sm:h-[24px]  md:w-[24px] md:h-[24px] "
                loading="eager"
                alt=""
                src={HeaderIcon}
              />
              <span className="uppercase font-bold font-nunito-sans lg:text-[32px] text-base sm:text-base md:text-base leading-[21px] ">
                Living Image
              </span>
            </div>
            <div className="flex items-center lg:order-2 lg:hidden">
              <div className="h-[63px] justify-end gap-x-5 font-inter font-nunito-sans flex">
                {/* here */}

                <button
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <svg
                    className="hidden w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={`${
                isVisible ? "" : "hidden"
              } "justify-between items-center w-full lg:flex lg:w-auto lg:order-1"`}
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-medium font-nunito-sans lg:flex-row lg:space-x-8 lg:mt-0">
                <li
                  className={`${
                    pathname === "/"
                      ? "lg:border-b-2 lg:border-transparent"
                      : "lg:border-b-0 lg:border-transparent"
                  } flex items-center lg:py-5  sm:border-0 md:border-0`}
                  style={{
                    borderImage:
                      "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                    borderImageSlice: "1",
                  }}
                  onClick={() => handleRout("/")}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer">
                    <div className="relative  text-[#000] font-extrabold font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg">
                      Home
                    </div>
                  </div>
                </li>
                <li
                  className={`${
                    pathname === "/faq"
                      ? "lg:border-b-2 lg:border-transparent"
                      : "lg:border-b-0 lg:border-transparent"
                  } flex items-center lg:py-5  sm:border-0 md:border-0`}
                  style={{
                    borderImage:
                      "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                    borderImageSlice: "1",
                  }}
                  onClick={generateReport}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer ">
                    <div className="relative leading-[130%]  text-[#000] font-extrabold font-nunito-sans text-xl">
                      FAQs
                    </div>
                  </div>
                </li>
                <li
                  className={`${
                    pathname === "/pricing"
                      ? "lg:border-b-2 lg:border-transparent"
                      : "lg:border-b-0 lg:border-transparent"
                  } flex items-center lg:py-5  sm:border-0 md:border-0`}
                  style={{
                    borderImage:
                      "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                    borderImageSlice: "1",
                  }}
                  onClick={() => handleRout("/pricing")}
                >
                  <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer ">
                    <div className="relative leading-[130%]  text-[#000] font-extrabold font-nunito-sans text-xl">
                      Pricing
                    </div>
                  </div>
                </li>
                {verified !== true ? (
                  <li
                    className={`${
                      pathname === "/register"
                        ? "lg:border-b-2 lg:border-transparent"
                        : "lg:border-b-0 lg:border-transparent"
                    } flex items-center lg:py-5  sm:border-0 md:border-0`}
                    style={{
                      borderImage:
                        "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                      borderImageSlice: "1",
                    }}
                    onClick={() => handleRout("/register")}
                  >
                    <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer ">
                      <div className="relative leading-[130%]  text-[#000] font-extrabold font-nunito-sans text-xl">
                        Sign Up
                      </div>
                    </div>
                  </li>
                ) : (
                  <li
                    className={`${
                      pathname === "/register"
                        ? "lg:border-b-2 lg:border-transparent"
                        : "lg:border-b-0 lg:border-transparent"
                    } flex items-center lg:py-5  sm:border-0 md:border-0`}
                    style={{
                      borderImage:
                        "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                      borderImageSlice: "1",
                    }}
                    onClick={() => handleRout("/dashboard")}
                  >
                    <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer ">
                      <div className="relative leading-[130%]  text-[#000] font-extrabold font-nunito-sans text-xl">
                        Dashboard
                      </div>
                    </div>
                  </li>
                )}
                {verified !== true ? (
                  <li
                    className={`${
                      pathname === "/login"
                        ? "lg:border-b-2 lg:border-transparent"
                        : "lg:border-b-0 lg:border-transparent"
                    } flex items-center lg:py-5  sm:border-0 md:border-0`}
                    style={{
                      borderImage:
                        "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                      borderImageSlice: "1",
                    }}
                    onClick={() => handleRout("/login")}
                  >
                    <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer ">
                      <div className="relative leading-[130%]  text-[#000] font-extrabold font-nunito-sans text-xl">
                        Login
                      </div>
                    </div>
                  </li>
                ) : (
                  <li
                    className={`${
                      pathname === "/login"
                        ? "lg:border-b-2 lg:border-transparent"
                        : "lg:border-b-0 lg:border-transparent"
                    } flex items-center lg:py-5  sm:border-0 md:border-0`}
                    style={{
                      borderImage:
                        "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                      borderImageSlice: "1",
                    }}
                    onClick={handleLogout}
                  >
                    <div className="buttons-states-dark3 h-12 rounded-md flex flex-row items-center justify-start px-3 py-0 box-border cursor-pointer ">
                      <div className="relative leading-[130%]  text-[#000] font-extrabold font-nunito-sans text-xl">
                        Log out
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
