import React from 'react';
import Header from "./../components/header/index";
import Footer from "./../components/footer/index"

const TermsOfService = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <main className="flex-grow container mx-auto px-4 py-8">
                <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
                <p className="text-gray-600 mb-2">Last Updated: MARCH 8TH 2024</p>
                <p className="mb-4">Welcome to Living Image!</p>
                <p className="mb-4">
                    These Terms of Service ("Terms") govern your access to and use of Living Image services and any information, text, graphics, photos, or other materials uploaded, downloaded, or appearing on Living Image (collectively referred to as "Content"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. By accessing or using the Service you agree to be bound by these Terms.
                </p>
                <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
                <p className="mb-4">
                    By accessing or using Living Image, you agree to be bound by these Terms, whether you are a registered user or just a visitor. If you do not agree with any of these terms, you are prohibited from using or accessing Living Image.
                </p>
                <h2 className="text-2xl font-semibold mb-2">2. Using Our Services</h2>
                <p className="mb-2 font-semibold">What You Can Do:</p>
                <p className="mb-4">
                    Subject to your compliance with these Terms, you may access and use our Services. In using our Services, you must comply with all applicable laws as well as our Sharing & Publication Policy, Usage Policies, and any other documentation, guidelines, or policies we make available to you.
                </p>
                <p className="mb-2 font-semibold">What You Cannot Do:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>Use our Services in a way that infringes, misappropriates or violates anyone’s rights.</li>
                    <li>Modify, copy, lease, sell or distribute any of our Services.</li>
                    <li>Attempt to or assist anyone to reverse engineer, decompile or discover the source code or underlying components of our Services, including our models, algorithms, or systems (except to the extent this restriction is prohibited by applicable law).</li>
                    <li>Automatically or programmatically extract data or Output.</li>
                    <li>Represent that Output was human-generated when it was not.</li>
                    <li>Interfere with or disrupt our Services, including circumvent any rate limits or restrictions or bypass any protective measures or safety mitigations we put on our Services.</li>
                    <li>Use Output to develop models that compete with Living Image.</li>
                </ul>
                <h2 className="text-2xl font-semibold mb-2">3. User Accounts</h2>
                <p className="mb-4">
                    You may be required to create an account and provide certain information about yourself in order to use Living Image. You are responsible for maintaining the confidentiality of your account password and are solely responsible for all activities that occur under your account.
                </p>
                <h2 className="text-2xl font-semibold mb-2">4. Intellectual Property</h2>
                <p className="mb-4">
                    Living Image owns all rights, title, and interest in and to Living Image and the Content displayed on it. You may not modify, reproduce, distribute, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on Living Image without the prior written consent of Living Image.
                </p>
                <h2 className="text-2xl font-semibold mb-2">5. Ownership of Content</h2>
                <p className="mb-4">
                    As between you and Living Image, and to the extent permitted by applicable law, you retain your ownership rights in Input and own the Output. Living Image hereby assigns to you all its right, title, and interest, if any, in and to Output.
                </p>
                <h2 className="text-2xl font-semibold mb-2">6. Our Use of Content</h2>
                <p className="mb-4">
                    We may use Content to provide, maintain, develop, and improve our Services, comply with applicable law, enforce our terms and policies, and keep our Services safe.
                </p>
                <h2 className="text-2xl font-semibold mb-2">7. Disclaimer of Warranties</h2>
                <p className="mb-4">
                    OUR SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE.
                </p>
                <h2 className="text-2xl font-semibold mb-2">8. Limitation of Liability</h2>
                <p className="mb-4">
                    NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <h2 className="text-2xl font-semibold mb-2">9. Governing Law</h2>
                <p className="mb-4">
                    These Terms shall be governed and construed in accordance with the laws of California, without regard to its conflict of law provisions.
                </p>
                <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
                <p className="mb-4">
                    If you have any questions about these Terms, please contact us at support@livingimage.io
                </p>
                <p className="mb-4">
                    By using Living Image, you agree to abide by these Terms of Service.
                </p>
            </main>
            <Footer />
        </div>
    );
};

export default TermsOfService;
