import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import SideBar from "../components/sidebar";
import { useSelector } from "react-redux";
import { isUpdateSidebarValueData } from "../redux/dashbordSlice/dashbordSlice";

const Layout = ({ children }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [isLogin, setIsLogin] = useState(false);

  const isUpdateSidebarValue = useSelector(isUpdateSidebarValueData);

  useEffect(() => {
    const verified = JSON.parse(localStorage.getItem("Verified"));

    if (verified) {
      const noSidebarPaths = [
        "/orderpage",
        "/generateimageoutput",
        "/updateuploadedimage",
        "/",
        "/pricing",
        "/checkout",
        "/change-plan",
        "/payment/failed",
        "/payment/success",
      ];

      if (noSidebarPaths.includes(pathname)) {
        setIsLogin(false);
      } else {
        setIsLogin(true);
      }
    } else {
      setIsLogin(false);
    }
  }, [pathname, isUpdateSidebarValue]);

  return (
    <React.Fragment>
      <div className="w-full flex h-screen">
        {isLogin && (
          <div className="min-w-fit h-screen">
            <SideBar />
          </div>
        )}
        <div className="w-screen">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
