import React, { useEffect, useState } from "react";
import HomeHead from "./components/homeHead";
import BeforAAfter from "../../assets/home/beforAAfterImg.png";
import BeforImg from "../../assets/home/beforImg.png";
import AfterImg from "../../assets/home/afterImg.png";

import W01 from "../../assets/home/w01.svg";
import W02 from "../../assets/home/w02.svg";
import W03 from "../../assets/home/w03.svg";
import W04 from "../../assets/home/w04.svg";
import W05 from "../../assets/home/w05.svg";
import TimerImg from "../../assets/home/timerImg.png";

import PhotoFremImg from "../../assets/home/photoFremImg.png";
import Faq from "./components/faq";
import Button from "../../common/button";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { ImgComparisonSlider } from "@img-comparison-slider/react";

import WallImg1 from "../../assets/wallImg1.jpg";
import WallImg2 from "../../assets/wallImg2.jpg";
import WallImg3 from "../../assets/wallImg3.jpg";
import WallImg4 from "../../assets/wallImg4.jpeg";
import WallImg5 from "../../assets/wallImg5.jpg";
import MultiDot from "../../assets/multiDot01.svg";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUpdateSidebarValueData } from "../../redux/dashbordSlice/dashbordSlice";

const Home = () => {
  const weather = [
    {
      name: "New York",
      icon: W01,
      cel: "22°C",
    },
    {
      name: "Mexico",
      icon: W02,
      cel: "18°C",
    },
    {
      name: "Moscow",
      icon: W03,
      cel: "17°C",
    },
    {
      name: "Austin",
      icon: W04,
      cel: "23°C",
    },
    {
      name: "Columbus",
      icon: W05,
      cel: "21°C",
    },
  ];

  const wallPaperList = [
    TimerImg,
    BeforAAfter,
    WallImg1,
    WallImg2,
    WallImg3,
    WallImg4,
    WallImg5,
  ];
  const [selectedWallPaper, setSelectedWallPaper] = useState(0);
  const verified = JSON.parse(localStorage.getItem("Verified"));
  const navigate = useNavigate();
  const isUpdateSidebarValue = useSelector(isUpdateSidebarValueData);

  const handleRout = (rout) => {
    navigate(`${rout}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // if (selectedWallPaper === 6) {
      //   setSelectedWallPaper(1);
      // } else {
      //   setSelectedWallPaper(selectedWallPaper + 1);
      // }
      setSelectedWallPaper(
        (prevWallPaper) => (prevWallPaper + 1) % wallPaperList.length
      );
      console.log("Logs every minute", selectedWallPaper);
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  console.log("selectedWallPaper ==== ", selectedWallPaper);

  // useEffect(() => {
  //   console.log("verified == ", verified);
  //   if (verified === true) {
  //     console.log("verified if == ", verified);
  //     // navigate("/dashboard");
  //   }
  // }, [verified]);

  useEffect(() => {
    if (isUpdateSidebarValue) {
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="w-full">
        <HomeHead />

        {/* <div className="w-full flex flex-col justify-center items-center gap-8 container mx-auto mt-20">
          <div
            className="font-extrabold bg-gradient-to-r from-orange-500 via-red-500 to-pink-500 font-nunito-sans lg:text-5xl text-[28px] sm:text-[28px] md:text-[28px]  text-black md:w-[65%] w-[90%] text-center"
            style={{
              // background:
              //   "linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            Every memory, every weather: Perfectly fresh & updated
          </div>
          <div className="font-normal font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg text-[#616161] md:w-[65%] w-[90%] text-centers">
            Experience the essence of every moment with our live location-based
            weather updates. Keep your memories fresh and vibrant as we
            seamlessly adapt your photos to the current weather, ensuring that
            every glance brings you back to the place you love.
          </div>
          <div className="flex flex-wrap gap-4 lg:gap-10 sm:gap-5 md:gap-5 mt-5 justify-start pl-5 sm:pl-0 sm:justify-start items-center">
            {weather?.map((item, index) => {
              return (
                <div className="hover:bg-gradient-to-r from-[#CF1659] via-[#FB4EA1] to-[#FFA439] border border-[#CCCCCC] hover:border-none rounded-[20px] hover:shadow-[#FB4EA1_4px_2px_10px_0px]">
                  <div
                    key={index}
                    className=" bg-white m-[2px] rounded-[20px] flex flex-col gap-2 lg:w-[137px] w-[90px] sm:w-[90px] md:w-[90px] py-3 justify-center items-center"
                    style={{
                      //   borderWidth: "1px",
                      //   borderImageSource:
                      //     "linear-gradient(270deg, #CF1659 3.5%, #FB4EA1 40.5%, #FF5A82 57.5%, #DB684F 99.99%, #FFA439 100%)",
                      //   borderImageSlice: "1",
                      boxShadow:
                        "10px 10px 20px linear-gradient(270deg, #CF1659 3.5%, #FB4EA1 40.5%, #FF5A82 57.5%, #DB684F 99.99%, #FFA439 100%)",
                    }}
                  >
                    <div className="font-bold font-nunito-sans lg:text-xl text-sm sm:text-sm md:text-sm text-black">
                      {item?.name}
                    </div>
                    <img
                      src={`${item?.icon}`}
                      alt="weather img"
                      className="lg:h-[34px] lg:w-[47px] h-[20px] w-[25px] sm:h-[20px] sm:w-[25px] md:h-[20px] md:w-[25px]"
                    />
                    <div className="font-semibold font-nunito-sans lg:text-xl text-base sm:text-base md:text-base text-black">
                      {item?.cel}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        {/* <div className="my-10 container mx-auto flex items-center w-full relative">
          <button className="bg-white border border-[#616161] rounded-[15px] px-9 py-[15px] text-xl font-nunito-sans font-semibold text-[#616161] absolute z-50 left-8">
            Before
          </button>
          <button className="bg-white border border-[#616161] rounded-[15px] px-9 py-[15px] text-xl font-nunito-sans font-semibold text-[#616161] absolute z-50 right-8">
            After
          </button>
          <ImgComparisonSlider>
            <img
              src={BeforImg}
              slot="first"
              alt="Img"
              className="w-screen object-cover lg:h-[940px]  sm:h-[327px] h-[327px] md:h-[327px]"
            />
            <img
              src={AfterImg}
              slot="second"
              alt="Img"
              className="w-screen object-cover lg:h-[940px]  sm:h-[327px] h-[327px] md:h-[327px]"
            />
          </ImgComparisonSlider>
        </div> */}

        <div className="relative flex justify-center items-end lg:mb-[15%] mb-[70%] sm:mb-[40%] md:mb-[40%] w-screen">
          <div className="relative w-full">
            <img
              src={wallPaperList[selectedWallPaper]}
              alt="img"
              className="w-[27%] h-[35%]  absolute top-[15%] left-[23%]"
            />
            <img
              src={PhotoFremImg}
              alt="img"
              className="w-screen lg:h-[1107px] h-[275px] sm:h-[275px] md:h-[275px]"
            />
          </div>
          <div className="bg-white py-5 flex flex-col gap-y-8  justify-center items-center absolute lg:bottom-[-10%] bottom-[-120%] sm:bottom-[-80%] md:bottom-[-90%] z-20 lg:w-[50%] w-[80%] sm:w-[70%] md:w-[60%]">
            <div
              className="bg-gradient-to-r from-emerald-500 via-cyan-400 to-sky-500 font-extrabold font-nunito-sans lg:text-5xl text-2xl sm:text-2xl md:text-2xl text-black text-center w-[80%]"
              style={{
                // background:
                // "linear-gradient(270deg, #27DFA7 96.5%, #13E2CA 77.5%, #0FD6D2 48%, #0EB5DB 29.5%, #21A2D3 4%)",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Create your one of a kind picture frame.
            </div>
            <div className="font-normal font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg text-[#616161] text-center w-[80%]">
              Watch the seasons change, a person age, or your favorite tree grow
              in the comfort of your home.
            </div>

            <div>
              <Button
                color="grey"
                disabled={true}
                name={"Order Physical Digital Photo"}
                className="font-extrabold font-nunito-sans text-2xl text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
              />
            </div>
            <span className="text-[#838383] text-sm mt-[-20px]">
              (Coming Soon)
            </span>
          </div>
        </div>
        <div className="w-full mt-[120%] sm:mt-72 pl-3" id="FaqHome">
          <Faq />
        </div>
        <div className="h-full w-full container mx-auto px-10 hidden lg:flex relative overflow-hidden">
          <div className="w-full bg-gradient-to-r from-emerald-400 via-teal-400 to-cyan-400 rounded-[20px] lg:flex flex flex-col sm:flex-col lg:flex-row lg:relative border my-20">
            <div className="flex justify-end absolute w-full top-[-10%] right-[-20%]">
              <img
                className="absolute left-[-150px] top-[-30px]"
                src={MultiDot}
                alt="img"
              />
            </div>
            <div className="lg:w-[50%] lg:absolute right-10 top-[-10%] flex justify-end sm:w-full w-full">
              {/* <img
                src={BeforAAfter}
                alt="Img"
                className="lg:w-[437px] object-contain rounded-[15px] sm:w-full w-full h-[321px] sm:h-[321px] lg:h-[360px]"
              /> */}
              <div className="flex lg:w-[50%] items-center justify-end w-full relative">
                <button className="bg-white border border-[#616161] rounded-[15px] px-4 py-[5px] text-[11px] font-nunito-sans font-semibold text-[#616161] absolute z-50 left-8">
                  Before
                </button>
                <button className="bg-white border border-[#616161] rounded-[15px] px-4 py-[5px] text-[11px] font-nunito-sans font-semibold text-[#616161] absolute z-50 right-8">
                  After
                </button>
                <ImgComparisonSlider>
                  <img
                    src={BeforImg}
                    slot="first"
                    alt="Img"
                    className="lg:w-[437px] object-cover rounded-[15px] sm:w-full w-full h-[321px] sm:h-[321px] lg:h-[360px]"
                  />
                  <img
                    src={AfterImg}
                    slot="second"
                    alt="Img"
                    className="lg:w-[437px] object-cover rounded-[15px] sm:w-full w-full h-[321px] sm:h-[321px] lg:h-[360px]"
                  />
                </ImgComparisonSlider>
              </div>
            </div>
            <div className="lg:w-[50%] flex flex-col gap-4 py-8 pl-20 sm:rounded-[20px] sm:w-full w-full">
              <div className="font-extrabold font-nunito-sans lg:text-5xl text-[26px] sm:text-[26px] md:text-[26px] leading-[51px] text-[#fff] text-start w-[90%]">
                Keep living your memories everyday.
              </div>
              <div className="font-normal font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg text-[#fff] text-start w-[90%]">
                Our modern AI Image update now, you can keep your memory live
                and share it with your loved ones.
              </div>

              <Button
                name={"Get Started"}
                className="font-extrabold font-nunito-sans text-xl bg-white w-fit text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
                onClick={() => handleRout("/register")}
              />
            </div>
          </div>
        </div>
        {/* mobile only */}
        <div className="h-full w-full container mx-auto flex lg:hidden">
          <div className="w-full flex flex-col gap-y-5 my-20">
            <div className="flex w-full justify-center relative items-center">
              {/* <img
                src={BeforAAfter}
                alt="Img"
                className="object-contain w-full h-[321px] sm:h-[321px] lg:h-[360px]"
              /> */}
              {/* <div className="flex lg:w-[50%] items-center justify-end w-full relative"> */}
              <button className="bg-white border border-[#616161] rounded-[15px] px-4 py-[5px] text-[11px] font-nunito-sans font-semibold text-[#616161] absolute z-50 left-8">
                Before
              </button>
              <button className="bg-white border border-[#616161] rounded-[15px] px-4 py-[5px] text-[11px] font-nunito-sans font-semibold text-[#616161] absolute z-50 right-8">
                After
              </button>
              <ImgComparisonSlider>
                <img
                  src={BeforImg}
                  slot="first"
                  alt="Img"
                  className="w-screen h-[321px] sm:h-[321px] lg:h-[360px] object-cover rounded-[15px] "
                />
                <img
                  src={AfterImg}
                  slot="second"
                  alt="Img"
                  className="object-cover rounded-[15px] w-screen h-[321px] sm:h-[321px] lg:h-[360px]"
                />
              </ImgComparisonSlider>
              {/* </div> */}
            </div>
            <div className="flex bg-gradient-to-r from-emerald-400 via-teal-400 to-cyan-400 flex-col gap-4 py-8 pl-5  w-full justify-center items-center">
              <div className="font-extrabold font-nunito-sans lg:text-5xl text-[26px] sm:text-[26px] md:text-[26px] text-[#fff] text-start w-[90%]">
                Keep living your memories everyday.
              </div>
              <div className="font-normal font-nunito-sans lg:text-xl text-lg sm:text-lg md:text-lg text-[#fff] text-start w-[90%]">
                Our modern AI Image update now, you can keep your memory live
                and share it with your loved ones.
              </div>

              <Button
                name={"Get Started"}
                className="font-extrabold font-nunito-sans text-xl bg-white w-fit text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] border-gradient-[#FFA439, #FF5A82, #FB4EA1, #CF1659]-[#DB684F]"
                onClick={() => handleRout("/register")}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
