import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { logout } from "../Utils/Logout";
import redirectingImage from "./../assets/redirecting-to-checkout.gif";

const ChangePlan = () => {
  const [dots, setDots] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [countdown, setCountdown] = useState(5);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newMembershipId = searchParams.get("id");

  const accessToken = localStorage.getItem("AccessToken");

  const changeMembership = async () => {
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/update_membership/",
        {
            new_membership_id: newMembershipId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        setSuccessMessage("Membership Updated successfully!");
        localStorage.setItem("MembershipId", newMembershipId)
      } else {
        setError("Please try again or contact support if the issue still persists.");
      }
    } catch (error) {
      setError(error?.message || "An error occurred. Please try again or contact support if the issue still persists.");
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    if (!accessToken) {
      setError("You are not logged in. Please login first.");
    } else if (newMembershipId && error === '') {
      changeMembership();
    } else {
      setError("Please try again or contact support if the issue still persists.");
    }
  }, []);

  useEffect(() => {
    const dotSequence = ['.', '..', '...'];
    let index = 0;

    const intervalId = setInterval(() => {
      setDots(dotSequence[index]);
      index = (index + 1) % dotSequence.length;
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (error !== '' || successMessage !== '') {
      const countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval);
            window.location.href = "/dashboard";
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [error, successMessage]);

  return (
    <div className="flex items-center justify-around gap-5 px-20 h-screen">
      <div>
        <p className="text-8xl font-nunito-sans font-bold">
          {error === "" && successMessage === "" ? "Hang on," : successMessage !== "" ? "Done!" : "Oh no"}
        </p>
        <p className="text-3xl font-nunito-sans">
          {error === "" && successMessage === ""
            ? `We are updating your membership plan${dots}`
            : error !== ""
            ? <>
                An error occurred<br />{error}
                <br />
                <span className="text-xl">Redirecting to <a href="/dashboard" className="underline text-blue-500">dashboard</a> in {countdown}s.</span>
              </>
            : <>
                {successMessage}
                <br />
                <span className="text-xl">Redirecting to <a href="/dashboard" className="underline text-blue-500">dashboard</a> in {countdown}s.</span>
              </>
          }
        </p>
      </div>
      <img src={redirectingImage} alt="Redirecting.." className="hidden sm:block" />
    </div>
  );
};

export default ChangePlan;
