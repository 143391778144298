import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authstore/authSlice";
import dashbordSlice from "./dashbordSlice/dashbordSlice";

export default configureStore({
  reducer: {
    authData: authSlice,
    dashbordData: dashbordSlice,
  },
});
