import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import PricingPage from "./pages/pricingPage";
import DashboardPage from "./pages/dashboard";
import Layout from "./layout";
import CreditsPage from "./pages/credits";
import PaymentPage from "./pages/payment";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import OtpVerification from "./pages/otpVerification";
import ProfileSettings from "./pages/profileSettings";
import ImageLibrary from "./pages/imageLibrary";
import Forgotpassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetPassword";
import Orderpage from "./pages/orderpage";
import GenerateSingleImage from "./pages/generateSingleImage";
import UpdateUploadedImage from "./pages/updateUploadedImage";
import Upgrade from "./pages/upgrade";
import Checkout from "./pages/Checkout";
import PaymentFail from "./pages/PaymentFail";
import PaymentSuccess from "./pages/PaymentSuccess";
import Subscription from "./pages/Subscription";
import ChangePlan from "./pages/ChangePlan";
import TermsOfService from "./pages/TermsOfService";

function App() {
  // const isLogin = JSON.parse(localStorage.getItem("Verified"));

  return (
    <>
      <div className="min-h-[100vh] h-fit w-full">
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/credits" element={<CreditsPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/otp" element={<OtpVerification />} />
            <Route path="/settings" element={<ProfileSettings />} />
            <Route path="/imagelibrary" element={<ImageLibrary />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/orderpage" element={<Orderpage />} />
            <Route
              path="/generateimageoutput"
              element={<GenerateSingleImage />}
            />
            <Route
              path="/updateuploadedimage"
              element={<UpdateUploadedImage />}
            />
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment/failed" element={<PaymentFail />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/change-plan" element={<ChangePlan />} />

            <Route path="/terms-of-service" element={<TermsOfService />} />






          </Routes>
        </Layout>
      </div>
    </>
  );
}

export default App;
