import React, { useEffect, useState } from "react";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import LeftRowIcon from "../../assets/sidebar/leftRowIcon.svg";
import DashboardAIcon from "../../assets/sidebar/dashboardAIcon.svg";
import DashboardIAIcon from "../../assets/sidebar/dashboardIAIcon.svg";

import creditsAIcon from "../../assets/sidebar/creditsAIcon.svg";
import creditsIAIcon from "../../assets/sidebar/creditsIAIcon.svg";
import orderFrameAIcon from "../../assets/sidebar/orderFrameAIcon.svg";
import orderFrameIAIcon from "../../assets/sidebar/orderFrameIAIcon.svg";
import paymentsAIcon from "../../assets/sidebar/paymentsAIcon.svg";
import paymentsIAIcon from "../../assets/sidebar/paymentsIAIcon.svg";
import subscriptionIcon from "./../../assets/sidebar/subscription-icon.png"

import LeftArrowIcon from "../../assets/sidebar/leftArrowIcon.svg";
import SettingAIcon from "../../assets/sidebar/settingAIIcon.svg";
import SettingIAIcon from "../../assets/sidebar/creditsIAIcon.svg";
import MenuIcon from "../../assets/sidebar/menuIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  creditBalanceData,
  getCreditBalance,
  updateSidebarValue,
} from "../../redux/dashbordSlice/dashbordSlice";
import { logout } from "../../Utils/Logout";

const SideBar = () => {
  const [dashbordData, setDashbordData] = useState([
    {
      name: "Images Library",
      active: DashboardAIcon,
      inactive: DashboardIAIcon,
      isActive: true,
      isPath: "/dashboard",
    },
    // {
    //   name: "Images Library",
    //   active: imagesLibraryAIcon,
    //   inactive: imagesLibraryIAIcon,
    //   isActive: false,
    //   isPath: "/imagelibrary",
    // },
    
    {
      name: "Credits",
      active: creditsAIcon,
      inactive: creditsIAIcon,
      isActive: false,
      isPath: "/credits",
    },
    {
      name: "Payments",
      active: paymentsAIcon,
      inactive: paymentsIAIcon,
      isActive: false,
      isPath: "/payment",
    },
    {
      name: "Subscription",
      active: subscriptionIcon,
      inactive: subscriptionIcon,
      isActive: false,
      isPath: "/subscription",
    },
    {
      name: "Order Frame",
      subTitle: "(Coming Soon)",
      live: false,
      active: orderFrameAIcon,
      inactive: orderFrameIAIcon,
      isActive: false,
      // isPath: "/dashboard",
    },
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();

  const creditBalance = useSelector(creditBalanceData);

  console.log("creditBalance === ", creditBalance);

  console.log(pathname);

  const [isOpenSideBar, setIsOpenSideBar] = useState(true);
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();;
    dispatch(updateSidebarValue(true));
  };

  const handleRout = (rout) => {
    navigate(rout);
  };

  const accessToken = localStorage.getItem("AccessToken");

  const getCreditBalanceApi = async () => {
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-credit-balance/",
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        console.log(response.data, response);
        dispatch(getCreditBalance(response?.data?.Credits));
      }
    } catch (error) {
      console.log("Axios Error: ", error);
      dispatch(getCreditBalance(0));
      if (error?.response?.status === 401) {
        logout()
      }
    }
  };

  useEffect(() => {
    getCreditBalanceApi();
  }, []);

  return (
    <>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="sm:inline-flex md:inline-flex inline-flex lg:hidden items-start  text-sm text-gray-500 rounded-lg bg-[#FBFBFB] absolute top-[30px] left-[6%] lg:static"
        onClick={() => setIsOpenSideBar(!isOpenSideBar)}
      >
        <span className="sr-only">Open sidebar</span>
        <img className="p-2 mt-2 ms-3" loading="eager" alt="" src={MenuIcon} />
      </button>
      {/* className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" */}
      <div
        // id="default-sidebar"
        className={`${isOpenSideBar ? "" : "-translate-x-full "
          } fixed sm:fixed md:fixed lg:static top-0 left-0 w-64 h-screen transition-transform z-50`}
      // aria-label="Sidebar"
      >
        <div className="h-full flex flex-col justify-between px-3 py-4 overflow-y-auto bg-[#fff]">
          <ul className="space-y-1 font-medium">
            <div className="flex items-center justify-between p-5">
              <div
                className="flex gap-3 items-center justify-center cursor-pointer"
                onClick={() => {
                  handleRout("/");
                  dispatch(updateSidebarValue(true));
                }}
              >
                <img
                  className="h-[21px] w-[21px]"
                  loading="eager"
                  alt=""
                  src={HeaderIcon}
                />
                <span className="uppercase font-bold font-nunito-sans text-[24px] leading-[21px]">
                  Living Image
                </span>
              </div>
              <img
                className="h-[21px] w-[21px] sm:inline-flex inline-flex md:inline-flex lg:hidden"
                loading="eager"
                alt=""
                src={LeftRowIcon}
                //   onClick={() => handleRout("/")}
                onClick={() => setIsOpenSideBar(!isOpenSideBar)}
              />
            </div>
            {dashbordData?.map((item, index) => {
              return (
                <li key={index} onClick={() => handleRout(item?.isPath)}>
                  <div
                    className={`${pathname === item?.isPath && "bg-[#FF5A82]"
                      } flex items-center pl-8 gap-4 py-3 rounded-[90px] ${item?.live == false ? '' : 'cursor-pointer'}`}
                    style={{
                      boxShadow:
                        pathname === item?.isPath &&
                        "0px 10px 30px 0px #FF5A8266",
                    }}
                  >
                    <img
                      src={`${pathname === item?.isPath
                          ? item?.active
                          : item?.inactive
                        }`}
                      alt="img"
                      className="w-[20px]"
                    />
                    <span
                      className={`${pathname === item?.isPath
                          ? "text-[#fff]"
                          : "text-[#000]"
                        } font-bold font-nunito-sans text-lg`}
                        style={{
                          color:item?.live === false ? 'grey' : 'black'
                        }}
                    >
                      {item?.name}
                    </span>

                  </div>
                  <span
                    className={`${pathname === item?.isPath
                        ? "text-[#fff]"
                        : "text-[#a3a3a3]"
                      } font-nunito-sans text-sm pl-8 gap-4 py-3 relative top-[-20px] ml-[36px]`}
                  >
                    {item?.subTitle}
                  </span>
                </li>
              );
            })}
          </ul>
          <ul className="space-y-2 font-medium">
            <li>
              <div className="flex items-center pl-4">
                <div className="font-bold font-nunito-sans text-lg text-[#000] flex gap-2">
                  <span className="text-[#FF5A82]">
                    {creditBalance}
                  </span>
                  Credits Available
                </div>
              </div>
            </li>
            <li onClick={() => handleRout("/settings")}>
              <div
                className={`${pathname === "/settings" && "bg-[#FF5A82]"
                  } flex items-center pl-8 gap-4 py-3 rounded-[90px] cursor-pointer`}
                style={{
                  boxShadow:
                    pathname === "/settings" &&
                    "0px 10px 30px 0px #FF5A8266",
                }}
              >
                <img
                  src={`${pathname === "/settings"
                      ? SettingAIcon
                      : SettingIAIcon
                    }`}
                  alt="img"
                />
                <span
                  className={`${pathname === "/settings"
                      ? "text-[#fff]"
                      : "text-[#000]"
                    } font-bold font-nunito-sans text-lg`}
                >
                  Setting
                </span>
              </div>
            </li>
            <li>
              <div
                className="flex items-center pl-8 gap-4 py-3 rounded-[90px] cursor-pointer"
                onClick={handleLogout}
              >
                <img src={`${LeftArrowIcon}`} alt="img" />
                <span className="font-bold font-nunito-sans text-lg text-[#000]">
                  Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
