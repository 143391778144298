import React, { useState } from "react";
import axios from "axios";
import { logout } from "../../../Utils/Logout";
import { useEffect } from "react";

const HowCreditWork = () => {
  const [rangeBar, setRangeBar] = useState(3600);
  const [creditPrice, setCreditPrice] = useState();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hour ${minutes} minutes`;
  };

  const getCreditPricing = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        "https://api.livingimage.io/api/credit-pricing/",
        {},
        {}
      );

      if (response?.status === 200) {
        setCreditPrice(response?.data?.["Credit Pricing"]?.price);
      }
    } catch (error) {
      setError(error);
      if (error?.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCreditPricing();
  }, []);

  const updatesPerDay = Math.floor(86400 / rangeBar); // 86400 seconds in a day
  const estimatedCreditsPerDay = updatesPerDay;
  const estimatedCostPerDay = (updatesPerDay * creditPrice).toFixed(2);

  return (
    <>
      <div className="w-[100%] sm:w-[75%] sm:border sm:broder-[#CCCCCC] shadow sm:shadow-none bg-[#fff] p-10 flex flex-col gap-5 relative">
        <div className="font-extrabold font-nunito-sans lg:text-[42px] text-2xl sm:text-2xl md:text-2xl text-[#000] text-start">
          How Credits Work
        </div>
        <div className="font-semibold font-nunito-sans lg:text-2xl text-lg sm:text-lg md:text-lg leading-[32px] text-[#000] text-start">
          Each image generation, whether from an uploaded picture or a text
          prompt, costs one credit. One credit equals one image. Easily monitor
          and replenish your credits to keep creating stunning images.
        </div>
        {loading ? "Loading Cost Calculator..." :
        <div className="flex flex-col gap-4">
          <div className="font-semibold font-nunito-sans lg:text-2xl text-lg sm:text-lg md:text-lg leading-[32px] text-[#000] text-start">
            Cost Calculator
          </div>
          <div className="flex gap-5 items-center">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <div className="w-fit font-semibold font-nunito-sans text-sm text-[#333333] text-center border border-[#CCCCCC] rounded-[1px] py-1 px-2">
                  Update every {formatTime(rangeBar)}
                </div>
                <div className="font-semibold flex lg:hidden font-nunito-sans lg:text-lg text-[13px] sm:text-[13px] md:text-[13px] leading-[24px] text-[#000] text-start">
                  = {estimatedCreditsPerDay} estimated credits /day which costs ${estimatedCostPerDay}/day
                </div>
              </div>
              <div className="w-full">
                <input
                  id="default-range"
                  type="range"
                  min={60} // Minimum value set to 60 seconds (1 minute)
                  max={21600} // Maximum value set to 21600 seconds (6 hours)
                  step={60} // Step size set to 60 seconds (1 minute)
                  value={rangeBar}
                  onChange={(e) => setRangeBar(e?.target?.value)}
                  className="w-[50%] h-2 bg-[#FF5A82] rounded-lg appearance-none cursor-pointer absolute z-50"
                />
              </div>
            </div>
            <div className="font-semibold hidden lg:flex font-nunito-sans lg:text-lg text-[13px] sm:text-[13px] md:text-[13px] leading-[24px] text-[#000] text-start">
              = {estimatedCreditsPerDay} estimated credits /day which costs ${estimatedCostPerDay}/day
            </div>
          </div>
        </div>}
        <div className="w-full h-full justify-end items-end md:flex absolute hidden">
          <div
            className="h-[122px] w-[122px] rounded-lg "
            style={{
              background:
                "linear-gradient(270deg, #FB4EA1 40.5%, #FF5A82 57.5%, #FFA439 100%)",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default HowCreditWork;
