import React from "react";
import failImg from "./../assets/payment-fail-img.gif"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const PaymentFail = () => {
    const [count, setCount] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        if (count === 0) {
          navigate("/dashboard");
        } else {
          const countdown = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
          }, 1000);
    
          return () => clearInterval(countdown);
        }
      }, [count, navigate]);



    return (
        <div className="flex items-center justify-around gap-5 px-20 h-screen">
      <div>
        <p className="text-5xl font-nunito-sans font-bold">:( An error occured</p>
        <p className="text-xl font-nunito-sans mt-1">
          We got an error during payment. Please try again or contact support if issue persists.
        </p>
        <p className="font-nunito-sans mt-3 text-lg">
          Redirecting to <a className="text-blue-500 underline cursor-pointer" href="/dashboard">dashboard</a> in {count}.
        </p>
      </div>
      <img src={failImg} alt="Payment Fail" className="w-[60%] h-[60%] hidden sm:block"/>
    </div>
    )

}

export default PaymentFail;