import React, { useState } from "react";
import Button from "../../common/button";
import SearchIcon from "../../assets/searchIcon.svg";
import InformationLineIcon from "../../assets/informationLineIcon.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  creditBalanceData,
  updateDeashbordImgLibraryState,
  updateImgLibraryTabState,
} from "../../redux/dashbordSlice/dashbordSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { logout } from "../../Utils/Logout";

const UploadImgDrawer = ({ isOpen, setIsOpenDrawer }) => {
  const dispatch = useDispatch();

  const [privacyTab, setPrivacyTab] = useState(["Public", "Private"]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [generateImgError, setGenerateImgError] = useState("");
  const [selectedFrequencyType, setSelectedFrequencyType] = useState("hour");
  const [selectedFrequency, setSelectedFrequency] = useState(1);
  const [tag, setTag] = useState("");
  const [selectedImageType, setSelectedImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoder, setIsLoder] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [imageError, setImageError] = useState("");
  const [frequencyError, setFrequencyError] = useState("");
  const [selectedImg, setSelectedImg] = useState("");

  const accessToken = localStorage.getItem("AccessToken");

  const creditBalance = useSelector(creditBalanceData);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const imageFromHome = searchParams.get("image");
  const onlyPrompt = searchParams.get("prompt");
  const promptText = searchParams.get("promptText");

  const [prompt, setPrompt] = useState(promptText && promptText !== "undefined"  ? promptText : "");



  const [photoUrl, setPhotoUrl] = useState(
    imageFromHome == 2
      ? `${window.location.origin}/upload-images/image01.png`
      : imageFromHome == 3
      ? `${window.location.origin}/upload-images/image02.png`
      : imageFromHome == 4
      ? `${window.location.origin}/upload-images/image03.png`
      : imageFromHome == 5
      ? `${window.location.origin}/upload-images/image04.png`
      : ""
  );

  const HandleCloseDrawer = () => {
    setIsOpenDrawer(false);
    navigate("/dashboard");
    setSelectedTab(0);
    setGenerateImgError("");
    setSelectedFrequencyType("minute");
    setSelectedFrequency(1);
    setPrompt("");
    setTag("");
    setDescription("");
    setSelectedImg("");
    setPhotoUrl("");
    setImageError("");
    setFrequencyError("");
  };

  const handleImageChange = (e) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

    if (e?.target?.name === "photo") {
      const selectedFile = e?.target?.files[0];
      if (selectedFile && selectedFile.size > maxSizeInBytes) {
        setImageError("File size exceeds 2 MB. Please upload a smaller image.");
        setSelectedImg("");
        setPhotoUrl("");
        setSelectedImageType("");
        return;
      } else if (selectedFile) {
        setSelectedImg(selectedFile);
        setPhotoUrl("");
        setSelectedImageType("photo");
        setImageError("");
      } else {
        setSelectedImg("");
        setPhotoUrl("");
        setSelectedImageType("");
        setImageError("");
      }
    } else if (e?.target?.name === "photo_url") {
      setPhotoUrl(e?.target?.value);
      setSelectedImg("");
      setSelectedImageType("photo_url");
    }
    console.log("handleImageChange: selectedImageType =", e?.target?.name);
  };

  const handleGenerateImgApi = async () => {
    setIsLoder(true);

    const formData = new FormData();
    formData.append("frequency", selectedFrequency);
    formData.append("frequency_type", selectedFrequencyType);
    formData.append("prompt", prompt);
    formData.append("public", selectedTab === 0 ? "True" : "False");
    formData.append("tag", tag !== "" ? tag : "");
    if (selectedImageType === "photo") {
      console.log("Appending photo:", selectedImg);
      formData.append("photo", selectedImg);
    } else if (selectedImageType === "photo_url" && photoUrl) {
      console.log("Appending photo_url:", photoUrl);
      formData.append("photo_url", photoUrl);
    }
    formData.append(
      "user_image_name",
      imageName ? imageName : selectedImg?.name
    );
    formData.append("description", description !== "" ? description : "");

    console.log("FormData entries:", formData.entries());

    try {
      setGenerateImgError("");
      const response = await axios.post(
        "https://api.livingimage.io/api/upload/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("API response:", response);
      if (response?.status === 200) {
        setIsLoder(false);
        HandleCloseDrawer();
        dispatch(updateDeashbordImgLibraryState(true));
        dispatch(updateImgLibraryTabState(true));
        window.location.reload();
      }
    } catch (error) {
      console.log("Error during API request:", error);
      if (error?.response?.status === 413) {
        setGenerateImgError("Uploaded image is too large.");
      } else {
        setGenerateImgError(error?.response?.data?.Message || error?.message);
      }
      setIsLoder(false);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  const handleGenerateImg = () => {
    if (selectedImg === "" && !photoUrl && prompt.trim() === "") {
      setImageError("Please add an Image or enter a prompt");
    } else {
      setImageError("");
      handleGenerateImgApi();
    }
  };

  const handleDragOver = (e) => {
    setDragging(true);
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    setDragging(false);
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (validImageTypes.includes(file.type)) {
        if (file.size > maxSizeInBytes) {
          setImageError(
            "File size exceeds 2 MB. Please upload a smaller image."
          );
          setSelectedImg("");
          setPhotoUrl("");
          setSelectedImageType("");
          console.log("handleDrop: File size exceeds limit:", file);
        } else {
          setSelectedImg(file);
          setPhotoUrl("");
          setImageError(""); // Clear any previous image error
          setSelectedImageType("photo"); // Ensure selectedImageType is set correctly
          console.log("handleDrop: Valid image file dropped:", file);
        }
      } else {
        setImageError("Invalid file type. Please provide a valid image.");
        console.log("handleDrop: Invalid file type dropped:", file);
      }
    }
    setDragging(false);
  };

  return (
    <>
      <div
        id="drawer-example"
        className={`${
          isOpen ? "translate-x-0" : "translate-x-full"
        } fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white md:w-[50%] lg:w-[35%] w-full border`}
        tabindex="-1"
        aria-labelledby="drawer-label"
      >
        <div className="p-10 flex flex-col gap-5">
          <button
            className="text-black bg-transparent rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center"
            onClick={HandleCloseDrawer}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>

          {onlyPrompt === true ||
          (onlyPrompt === "true" && onlyPrompt !== null) ? (
            ""
          ) : (
            <div className="border-2 p-4 rounded-2xl">
              <h5 className="font-nunito-sans font-extrabold lg:text-4xl text-[30px] text-[#000] w-full text-center">
                Select & Upload
              </h5>
              <div
                className={`${
                  dragging ? "border" : "border-0"
                } border border-dashed rounded-xl border-[#616161]`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
              >
                <div className="flex justify-center items-center flex-col mt-5 mx-8">
                  <div className="flex w-full items-center justify-center bg-grey-lighter">
                    <label
                      className="w-full bg-white flex justify-center items-start text-black rounded-[120px] px-[40px] py-[10px] gap-[10px] border-[3px] cursor-pointer"
                      style={{
                        background:
                          " linear-gradient(to right, white, white), linear-gradient(270deg, rgb(152 28 28) 100%, rgb(255, 90, 130) 57.5%, rgb(251, 78, 161) 40.5%, rgb(207, 22, 89) 3.5%) 1 / 1 / 0 stretch",
                        backgroundClip: "padding-box, border-box",
                        backgroundOrigin: "padding-box, border-box",
                        border: "3px solid transparent",
                        borderRadius: "120px",
                      }}
                    >
                      <span
                        className="font-nunito-sans font-extrabold text-xl lg:text-2xl text-[#000] text-center p-2 border cursor-pointer w-auto"
                        style={{
                          background:
                            "linear-gradient(to right, #059669, #22D3EE, #0EA5E9)",
                          color: "#fff",
                          border: "3px solid transparent",
                          borderRadius: "120px",
                          padding: "10px 40px",
                          display: "inline-block",
                          textAlign: "center",
                          backgroundClip: "padding-box, border-box",
                          backgroundOrigin: "padding-box, border-box",
                        }}
                      >
                        Select Image
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          className="hidden"
                          onChange={handleImageChange}
                          name="photo"
                        />
                      </span>

                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        className="hidden"
                        onChange={handleImageChange}
                        name="photo"
                      />
                    </label>
                  </div>
                  {selectedImg && selectedImg?.name ? (
                    <div className="font-nunito-sans font-semibold text-xl my-4 text- items-center w-full flex flex-col text-[#616161] gap-3">
                      {selectedImg?.name}
                    </div>
                  ) : (
                    <div className="font-nunito-sans font-semibold text-xl mt-4 text- items-center w-full flex flex-col text-[#616161] gap-3">
                      or drop a file.
                      <span className="text-[#aeaeae] text-xs">
                        *It is recommended to upload image with square aspect
                        ratio and images of max <b>2 MB</b> are supported.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <p className="uppercase text-center">Or</p>
              <div className="flex w-full">
                <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full">
                  <input
                    type="text"
                    className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                    placeholder="Enter url of image"
                    value={photoUrl}
                    name="photo_url"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
          )}

          {onlyPrompt === true || onlyPrompt === "true"
            ? ""
            : imageError && (
              <div className="text-red-700 text-xs">{imageError}</div>
            )}

          <p
            className="bg-gradient-to-r from-emerald-500 via-cyan-400 to-sky-500 font-extrabold font-nunito-sans text-2xl text-center"
            style={{
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {onlyPrompt === true || onlyPrompt === "true"
              ? "Please enter prompt below"
              : "Or just enter prompt below:"}
          </p>
          <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full">
            <input
              type="text"
              className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
              placeholder="Enter Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e?.target?.value)}
            />
          </div>

          {onlyPrompt === true || onlyPrompt === "true"
            ? imageError && (
              <div className="text-red-700 text-xs">{imageError}</div>
            )
            : "" }

          <hr />

          <div className="flex flex-col w-full mt-5">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Select Type of regeneration:
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div>
              <select
                id="frequency-type"
                className="text-[#616161] outline-none border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-3 px-6 gap-2 mt-2 text-sm w-full"
                onChange={(e) => setSelectedFrequencyType(e?.target?.value)}
                value={selectedFrequencyType}
              >
                <option value="minute">Minute</option>
                <option value="hour">Hours</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Frequency of regeneration:
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full mt-2">
              <input
                type="number"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                placeholder="1"
                min="1"
                value={selectedFrequency}
                onChange={(e) => setSelectedFrequency(e?.target?.value)}
              />
            </div>
          </div>

          {frequencyError && (
            <div className="text-red-700 text-xs">{frequencyError}</div>
          )}
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Tag (Optional):
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full mt-2">
              <input
                type="text"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                placeholder="#tag"
                value={tag}
                onChange={(e) => setTag(e?.target?.value)}
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Image Name (Optional):
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="border border-[#CCCCCC] bg-[#FBFBFB] rounded-[80px] flex justify-center items-center py-2 px-6 gap-2 w-full mt-2">
              <input
                type="text"
                className="text-[#616161] outline-none px-1 bg-[#FBFBFB] w-full"
                placeholder="Image Name"
                value={imageName}
                onChange={(e) => setImageName(e?.target?.value)}
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Description (Optional):
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="rounded-[80px] flex justify-center items-center py-2 gap-2 w-full mt-2">
              <textarea
                id="message"
                rows="4"
                placeholder="description"
                value={description}
                className="text-[#616161] outline-none border border-[#CCCCCC] bg-[#FBFBFB] w-full block p-2.5 rounded-lg"
                onChange={(e) => setDescription(e?.target?.value)}
              ></textarea>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="font-nunito-sans font-bold text-lg text-[#000] flex gap-2 items-center">
              Privacy:
              <span>
                <img src={InformationLineIcon} alt="img" />
              </span>
            </label>
            <div className="flex justify-start items-center gap-5 mt-2">
              {privacyTab?.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={`${
                      selectedTab === index
                        ? "text-[#000] border-[2px] border-[#FF5A82]"
                        : "text-[#616161] bg-[#FBFBFB] border border-[#CCCCCC]"
                    } font-nunito-sans font-normal text-lg px-[30px] py-[8px] rounded-full`}
                    onClick={() => setSelectedTab(index)}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
          </div>
          {generateImgError && (
            <div className="text-red-700 w-full text-center mt-10">
              Oh! error occured, <br /> {generateImgError}
            </div>
          )}
          <div className="w-full flex justify-center items-center mt-10">
            <div className="flex text-center text-base font-nunito-sans font-semibold gap-2 text-[#000]">
              Credits:
              <span className="text-[#616161] underline">
                {creditBalance} credits available
              </span>
              |<span className="text-[#FF5A82] underline">Buy</span>
              credits
            </div>
          </div>
          <div className="flex justify-center items-center flex-col mx-8">
            {isLoder ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Button
                name={"Generate Image"}
                className={
                  "font-nunito-sans font-extrabold text-xl text-[#000] w-full"
                }
                onClick={handleGenerateImg}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImgDrawer;
