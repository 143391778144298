import React, { useEffect, useState } from "react";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import Button from "../../common/button";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import AuthRightCard from "../../components/AuthRightCard";
import { logout } from "../../Utils/Logout";
import queryString from "query-string";
import crossIcon from "./../../assets/cross-icon.png"

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { upload, prompt, promptText } = queryString.parse(location.search);

  const initialvalue = {
    email: "",
    new_password: "",
    verification_code: "",
  };

  const [resetPassData, setResetPassData] = useState(initialvalue);
  const [isLoder, setIsLoder] = useState(false);
  const [mailErrorMsg, setMailErrorMsg] = useState("");
  const [PasswordErrorMsg, setPasswordErrorMsg] = useState("");
  const [vCodeErrorMsg, setVCodeErrorMsg] = useState("");
  const [erroeMsg, setErroeMsg] = useState("");
  const [isResandLoder, setIsResandLoder] = useState(false);
  const [resendSuccessMsg, setResendSuccessMsg] = useState("");
  const [resendErrMsg, setResendErrMsg] = useState("");
  const [timer, setTimer] = useState(0);

  const emailAddress = localStorage.getItem("EmailAddress");

  const handleChange = (e) => {
    setResetPassData({ ...resetPassData, [e?.target?.name]: e?.target?.value });
  };

  const signinApiCall = async (data) => {
    setIsLoder(true);
    const url = "https://api.livingimage.io/api/reset-password/";
    const header = {
      "Content-Type": "application/json",
    };
    try {
      await axios.post(url, data, header).then((res) => {
        console.log("RESPONSE RECEIVED: ", res, res?.status);
        if (res?.status === 200) {
          setResetPassData(initialvalue);
          setIsLoder(false);
          setErroeMsg(res?.data?.Message);
          setResetPassData(initialvalue);
          navigate(`/login?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`);
        }
      });
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
      setIsLoder(false);
      setResetPassData(initialvalue);
      setErroeMsg(err?.response?.data?.Message);
      if (err?.response?.status === 401) {
        logout();
      }
    }
  };

  const handleSignIn = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (
      resetPassData?.email === "" ||
      resetPassData?.new_password === "" ||
      resetPassData?.verification_code === "" ||
      !emailRegex?.test(resetPassData?.email)
    ) {
      if (resetPassData?.email === "") {
        setMailErrorMsg("Please enter email");
      } else if (emailRegex?.test(resetPassData?.email)) {
        setMailErrorMsg("");
      } else {
        setMailErrorMsg("Please enter valid email");
      }

      if (resetPassData?.new_password === "") {
        setPasswordErrorMsg("Please enter new password");
      } else {
        setPasswordErrorMsg("");
      }

      if (resetPassData?.verification_code === "") {
        setVCodeErrorMsg("Please enter verification code");
      } else {
        setVCodeErrorMsg("");
      }
    } else if (
      resetPassData?.email !== "" &&
      resetPassData?.new_password !== "" &&
      resetPassData?.verification_code !== ""
    ) {
      console.log("resetPassData === ", resetPassData);
      signinApiCall(resetPassData);
    }
  };

  useEffect(() => {
    if (resetPassData?.email === "") {
      setMailErrorMsg("");
    }
    if (resetPassData?.new_password === "") {
      setPasswordErrorMsg("");
    }
    if (resetPassData?.verification_code === "") {
      setVCodeErrorMsg("");
    }
  }, [resetPassData]);

  useEffect(() => {
    setTimeout(() => {
      setErroeMsg("");
    }, 3000);
  }, [erroeMsg]);

  const handleResendOtp = async () => {
    setIsResandLoder(true);
    const url = "https://api.livingimage.io/api/resendotp/";
    const header = {
      "Content-Type": "application/json",
    };
    const data = {
      email: emailAddress,
    };
    try {
      await axios.post(url, data, header).then((res) => {
        console.log("RESPONSE RECEIVED: ", res, res?.status);
        if (res?.status === 200) {
          setIsResandLoder(false);
          setResendSuccessMsg(res?.data?.Message);
          setTimer(30); // Start the timer after successful OTP resend
        }
      });
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
      setIsResandLoder(false);
      setResendErrMsg(err?.response?.data?.Message);
      if (err?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    setTimeout(() => {
      setResendSuccessMsg("");
      setResendErrMsg("");
      setErroeMsg("");
    }, 3000);
  }, [resendSuccessMsg, erroeMsg, resendErrMsg]);

  const verified = JSON.parse(localStorage.getItem("Verified"));

  useEffect(() => {
    if (verified === true) {
      navigate(`/dashboard?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`);
    }
  }, [verified, upload, prompt, promptText, navigate]);

  return (
    <>
      <div
        className="h-screen  w-full flex justify-center items-center"
        style={{
          background: "linear-gradient(to right top, white 40%, #b7f0f2)",
        }}
      >
        <div className="relative min:w-[640px] w-full grid grid-cols-1 sm:grid-cols-2 m-5 md:w-[75%] max my-36 rounded-2xl bg-[#fff] min-h-[80vh]">


        <div
          className="rounded-[50%] bg-[#f84343] text-gray-500 absolute sm:hidden  top-6 right-6 py-2 px-2 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={crossIcon} alt="Close" srcset="" className="mx-1 my-1" />
        </div>


          <div className="flex flex-col items-center justify-center gap-4 py-20 md:mx-20 mx-5 ">
            <div className="flex gap-2 items-center justify-center w-full">
              <img
                className="lg:w-[45px] lg:h-[45px]  w-[45px] h-[45px]  sm:w-[45px] sm:h-[45px]  md:w-[45px] md:h-[45px] "
                loading="eager"
                alt=""
                src={HeaderIcon}
              />
              <span className="uppercase font-bold flex flex-col font-nunito-sans lg:text-[32px] text-base sm:text-base md:text-base leading-[21px] ">
                LivingImage
              </span>
            </div>
            <div
              className="bg-gradient-to-r from-emerald-500 via-cyan-400 to-sky-500 font-nunito-sans font-extrabold text-3xl mt-4"
              style={{ backgroundClip: "text", color: "transparent" }}
            >
              Verify OTP
            </div>

            <div className="flex flex-col gap-2 w-full">
              <input
                type="email"
                placeholder="E-Mail"
                onChange={handleChange}
                value={resetPassData?.email}
                name="email"
                className="font-nunito-sans font-normal text-2xl text-[#616161] w-full border border-[#CCCCCC] bg-[#FBFBFB] py-3 px-8 outline-none rounded-lg"
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {mailErrorMsg}
              </div>
              <input
                type="password"
                placeholder="New Password"
                onChange={handleChange}
                value={resetPassData?.new_password}
                name="new_password"
                className="font-nunito-sans font-normal text-2xl text-[#616161] w-full border border-[#CCCCCC] bg-[#FBFBFB] py-3 px-8 outline-none rounded-lg"
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {PasswordErrorMsg}
              </div>
              <input
                type="number"
                placeholder="Verification Code"
                onChange={handleChange}
                value={resetPassData?.verification_code}
                name="verification_code"
                className="font-nunito-sans font-normal text-2xl text-[#616161] w-full border border-[#CCCCCC] bg-[#FBFBFB] py-3 px-8 outline-none rounded-lg"
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {vCodeErrorMsg}
              </div>
            </div>
            <div style={{ color: "red", fontSize: "12px" }}>{erroeMsg}</div>
            <div style={{ color: "green", fontSize: "14px" }}>
              {resendSuccessMsg}
            </div>
            <div style={{ color: "red", fontSize: "12px" }}>{resendErrMsg}</div>
            <div className="w-full flex justify-center items-center">
              {isLoder ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button
                  name={"Reset Password"}
                  className={"w-full"}
                  onClick={handleSignIn}
                />
              )}
            </div>

            <div className="w-full flex justify-center items-center">
              {isResandLoder ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div>
                  {timer > 0 ? (
                    <div className="font-nunito-sans font-semibold text-xl text-[#616161]">
                      Resend OTP in {timer}s
                    </div>
                  ) : (
                    <div
                      className="font-nunito-sans font-semibold text-xl text-[#616161] cursor-pointer"
                      onClick={handleResendOtp}
                    >
                      Resend OTP
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="text-center visible sm:hidden">
            <div className="font-nunito-sans text-2xl">Got your password?</div>
            <a
              href={`/login?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`}
              className="text-2xl underline font-nunito-sans"
            >
              Sign In
            </a>
          </div>

          </div>

          <AuthRightCard
            heading="Hello, User!"
            paragraph="Please enter your email and an otp received on your registered email address. Then, create a strong password."
            subtext="Got your password?"
            buttonTxt="Sign in"
            buttonUrl={`/login?upload=${upload}&prompt=${prompt}&promptText=${encodeURIComponent(promptText)}`}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
