import React, { useEffect, useState } from "react";
import GenerateSingleImageSideBar from "../../components/GenerateSingleImageSideBar";
import Button from "../../common/button";
import BeforImg from "../../assets/home/beforImg.png";
import AfterImg from "../../assets/home/afterImg.png";
import HeaderIcon from "../../assets/Heaader/HeaderIcon.svg";
import LeftNarrowIcon from "../../assets/leftNarrowIcon.svg";
import FacebookIcon from "../../assets/facebookLineIcon.svg";
import InstagramIcon from "../../assets/instagramLineIcon.svg";
import WhatsappIcon from "../../assets/whatsappIcon.svg";
import ShareIcon from "../../assets/shareIcon.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  creditBalanceData,
  updateSidebarValue,
} from "../../redux/dashbordSlice/dashbordSlice";
import { useRef } from "react";
import { logout } from "../../Utils/Logout";

const GenerateSingleImage = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("AccessToken");
  const [originalImgErrorMsg, setOriginalImgErrorMsg] = useState("");
  const [isLoderOriginalImg, setIsLoderOriginalImg] = useState(false);
  const [regenImgErrorMsg, setRegenImgErrorMsg] = useState("");
  const [isLoderRegenImg, setIsLoderRegenImg] = useState(false);
  const [originalImg, setOriginalImg] = useState("");
  const [regenImg, setRegenImg] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [originalImgData, setOriginalImgData] = useState("");

  const creditBalance = useSelector(creditBalanceData);

  const imageIdData = localStorage.getItem("imageIdOrignandRegen");

  const imageRef = useRef(null);

  useEffect(() => {
    const generateReloadKey = () => {
      setReloadKey(Math.random());
    };
    generateReloadKey();
    const interval = setInterval(generateReloadKey, 60000);
    return () => clearInterval(interval);
  }, []);

  const getOneOriginalImage = async () => {
    setIsLoderOriginalImg(true);
    const data = { image_id: JSON.parse(imageIdData)?.original_image_id };
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-one-original-image/",
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        console.log(response.data, response);
        setOriginalImg(response?.data?.Original_Image_data?.original_image);
        setOriginalImgData(response?.data?.Original_Image_data);
        setIsLoderOriginalImg(false);
      }
    } catch (error) {
      console.log("Axios Error: ", error);
      setOriginalImgErrorMsg(error?.response?.data?.Message);
      setIsLoderOriginalImg(false);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };
  const getOneRegenImage = async (loader = true) => {
    setIsLoderRegenImg(loader);
    const data = { image_id: JSON.parse(imageIdData)?.regenerated_image_id };
    try {
      const response = await axios.post(
        "https://api.livingimage.io/api/get-one-regen-image/",
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.status === 200) {
        console.log(response.data, response);
        setRegenImg(response?.data?.Regenerated_Image_data?.regenerated_image);

        setIsLoderRegenImg(false);
      }
    } catch (error) {
      console.log("Axios Error: ", error);
      setRegenImgErrorMsg(error?.response?.data?.Message);
      setIsLoderRegenImg(false);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    getOneOriginalImage();
    getOneRegenImage();
  }, []);

  useEffect(() => {
    dispatch(updateSidebarValue(false));
    // localStorage.setItem("Verified", false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // setOriginalImgErrorMsg("");
      // setRegenImgErrorMsg("");
    }, 3000);
  }, []);

  const toggleFullScreen = () => {
    const element = imageRef.current;
    const isFullScreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;

    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <>
      <div className="bg-[#FBFBFB] lg:bg-none border border-[#CCCCCC] lg:border-none rounded-[20px] m-5 lg:m-0">
        <div className="w-full flex lg:h-screen">
          <div className="min-w-fit lg:h-screen">
            <GenerateSingleImageSideBar />
          </div>
          <div className="w-screen">
            <div className="w-full flex lg:bg-[#FBFBFB] lg:h-screen lg:overflow-auto">
              <div className="w-full flex flex-col items-center gap-5 my-5 lg:pb-5">
                <div className="w-[90%] lg:h-screen my-5 lg:pb-5">
                  <div className="w-full lg:border border-[#CCCCCC] rounded-[20px] lg:bg-[#fff] flex flex-col px-5 py-5 gap-2 h-fit mb-10">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                      <div className="font-nunito-sans font-extrabold lg:text-[28px] text-2xl text-[#000]">
                        Order physical digital photo frame.
                        <span className="text-xs text-[#989797]">
                          &nbsp; (Coming Soon)
                        </span>
                      </div>
                      <div>
                        <Button
                          color="grey"
                          disabled={true}
                          name={"Order Frame"}
                          className={
                            "font-nunito-sans font-extrabold text-2xl text-[#000]"
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row w-full gap-5 my-5">
                      <div className="bg-[#EAEAEA] p-3 rounded-[10px] w-full h-auto flex flex-col justify-between lg:w-[50%] bg-gradient-to-r from-green-500 to-cyan-400">
                        {isLoderRegenImg ? (
                          <div
                            role="status"
                            className="h-fit flex justify-center items-center"
                          >
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : regenImgErrorMsg ? (
                          <div className="h-fit flex justify-center items-center">
                            <div className="text-sm text-red-700 w-full text-center mt-10">
                              {regenImgErrorMsg}
                            </div>
                          </div>
                        ) : (
                          <img
                            src={
                              regenImg +
                              `?reload=${reloadKey}&timestamp=${Date.now()}`
                            }
                            alt="img"
                            className={
                              "transition-opacity w-full h-fit object-fill rounded-[10px]" +
                              " regen-img"
                            }
                            ref={imageRef}
                            style={{
                              cursor: "pointer",
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                            onClick={toggleFullScreen}
                          />
                        )}
                        <div className="font-nunito-sans text-[15px] text-[#000] w-full text-center pt-1 ">
                          Click on image to view full screen.
                        </div>
                        <div className="font-nunito-sans font-bold text-[32px] text-[#000] w-full text-center pt-2 ">
                          Latest Update
                        </div>
                      </div>
                      <div className="bg-[#EAEAEA] h-auto flex flex-col justify-between p-3 rounded-[10px] w-full lg:w-[50%]">
                        {isLoderOriginalImg ? (
                          <div
                            role="status"
                            className="h-fit flex justify-center items-center"
                          >
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : originalImgErrorMsg ? (
                          <div className="h-fit flex justify-center items-center">
                            <div className="text-sm text-red-700 w-full text-center mt-10">
                              {originalImgErrorMsg}
                            </div>
                          </div>
                        ) : originalImg === "" || !originalImg ? (
                          <div className="w-full object-fill rounded-[10px] flex items-center justify-center h-[-webkit-fill-available] bg-white">
                            <p 
                            className="font-nunito-sans text-4xl font-bold p-2 text-center bg-gradient-to-r from-orange-500 via-red-500 to-pink-500"
                            style={{
                              backgroundClip: "text",
                              color: "transparent",
                            }}
                            >
                              {originalImgData?.prompt}
                            </p>
                          </div>
                        ) : (
                          <img
                            src={originalImg}
                            alt="img"
                            className="w-full h-fit object-fill rounded-[10px]"
                          />
                        )}
                        <div className="font-nunito-sans font-bold text-[32px] text-[#000] w-full text-center pt-9 ">
                          {originalImg === "" || !originalImg
                            ? "Prompt"
                            : "Original"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </>
  );
};

export default GenerateSingleImage;
